import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { blueBackgroundCard, violetBackgroundCard } from "./assets";

// Customizable Area Start
import { checkLoggedInUserSettings } from "./utility.web";
import { getStorageData } from "../../../framework/src/Utilities";


const images = [
  blueBackgroundCard,
  violetBackgroundCard
]
interface NotificationAttributes {
  id: number;
  setting_type: string;
  notifies: boolean;
  follow_up: boolean;
  days: number | string;
  notify_desc: string;
  follow_up_desc: string;
}

interface NotificationData {
  id: string;
  type: string;
  attributes: NotificationAttributes;
}

interface GetNotificationAttributes {
  id: number;
  setting_type: string;
  notifies: boolean;
  follow_up: boolean;
  days: number;
}

interface GetNotificationData {
  id: string;
  type: string;
  attributes: GetNotificationAttributes;
}
interface PlanData{
  current_plan:string
  plans:PlanMonthly
  subscription_id : number
  errors: { token: string; }[]
}
interface PlanMonthly {
  data: PlanAttributes[]
}
interface PlanAttributes{
  attributes: {
    id: string;
    plan_type: string;
    plan_price: number;
    currency: string;
    amount_of_slider:number
    body: string;
    button_text: string
    button_text_setting: string
    plan_description: {
      id: number;
      plan_type: string;
      discription: string;
    }[];
    buttonBody: string;
    buttonText: string;
    choosen: boolean;
    details: string
  },
}
interface GetResponseProp {
  data:GetNotificationData[],
  errors:{token:string}[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler: Function
  history: any
  location: any
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showText: boolean;
  showTextNotification: boolean;
  cardImages: string[];
  currentImageIndex: number;
  isEmployee: boolean;
  currentPlan:string;
  loading: boolean
  languageSelected: string;
  mockNotificationData:NotificationData[];
  firstTimeData:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationSettingApiCallId: string = ""
  getPlanApiCallId: string = ""
  createNotificationSettingApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      firstTimeData:false,
      mockNotificationData:[],
      showText: false,
      showTextNotification: false,
      cardImages: images,
      currentPlan:'',
      currentImageIndex: 0,
      isEmployee: false,
      loading: false,
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const mockNotificationData = [
      {
          "id": "1",
          "type": "notification_settings",
          "attributes": {
              "id": 1,
              "setting_type": "request",
              "notifies": true,
              "follow_up": true,
              "days": 3,
              "notify_desc":this.props.t("settings.requestDesc"),
              "follow_up_desc":this.props.t("settings.requestDescSecond"),
          }
      },
      {
          "id": "2",
          "type": "notification_settings",
          "attributes": {
              "id": 2,
              "setting_type": "job",
              "notifies": true,
              "follow_up": true,
              "days": 3,
              "notify_desc":"",
              "follow_up_desc":this.props.t("settings.jobDescEg"),
          }
      },
      {
          "id": "3",
          "type": "notification_settings",
          "attributes": {
              "id": 3,
              "setting_type": "quote",
              "notifies": true,
          "follow_up": true,
          "days": 2,
          "notify_desc": "",
          "follow_up_desc": this.props.t("settings.quoteDescEg"),
        }
      },
      {
        "id": "4",
        "type": "notification_settings",
        "attributes": {
          "id": 4,
          "setting_type": "invoice",
          "notifies": true,
          "follow_up": true,
          "days": 4,
          "notify_desc": "",
          "follow_up_desc": this.props.t("settings.invoiceDescEg"),
        }
      }
  ]
    this.getPlan()
    this.setState({mockNotificationData},this.handleGetNotification);
    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });
    
    const user = await checkLoggedInUserSettings()
    this.setState({ isEmployee: user })
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
       if (apiRequestCallId === this.getNotificationSettingApiCallId) {
        this.handleNotificationResponse(responseJson)
      } else if (apiRequestCallId === this.createNotificationSettingApiCallId) {
        this.handleCreateNotificationResponse(responseJson)
      } else if (apiRequestCallId === this.getPlanApiCallId) {
        this.getPlanApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleNotificationResponse = (responseJson: GetResponseProp) => {
    this.setState({ loading: false })
    if (responseJson && !responseJson.errors) {
      if (responseJson.data.length > 0) {
        this.setState({
          mockNotificationData: responseJson.data.map((item: GetNotificationData) => {
            if (item.attributes.setting_type === "request") {
              return {
                ...item,
                attributes: {
                  ...item.attributes,
                  "notify_desc": this.props.t("settings.requestDesc"),
                  "follow_up_desc": this.props.t("settings.requestDescSecond"),
                }
              }

            } else {
              return {
                ...item,
                attributes: {
                  ...item.attributes,
                  "notify_desc": "",
                  "follow_up_desc": this.getLangText(item.attributes.setting_type) ,
                }
              }

            }
          }), firstTimeData: true
        })
      }
    } else if (responseJson && responseJson.errors) {
      const { token } = responseJson.errors[0]
      this.props.openToastHandler(token , "error")
    }
  };
  getPlan = () => {
    const token = localStorage.getItem(configJSON.authToken)
    const header = {
      "token": token
    };
    const apiEndPoint = configJSON.getPlanAPIEndPoint
    this.setState({ loading: true })
    let getPlanUrl
    getPlanUrl = `${apiEndPoint}?key=monthly&lang=${localStorage.getItem('lang')}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPlanApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getPlanUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPlanApiResponse=(responseJson:PlanData)=>{
    if (responseJson && !responseJson.errors) {
        this.setState({ currentPlan: responseJson.current_plan,loading:false })
      }
  }
  getLangText = (type: string) => {
    if (type === "job") {
      return this.props.t("settings.jobDescEg")
    } else if (type === "quote") {
      return this.props.t("settings.quoteDescEg")
    } else {
      return this.props.t("settings.invoiceDescEg")
    }
  }

  handleCreateNotificationResponse = (responseJson: GetResponseProp) => {
    this.setState({ loading: false })
    if (responseJson && !responseJson.errors) {
      this.handleGetNotification()
      // this.setState({ mockNotificationData: responseJson, firstTimeData:true})
    } else if (responseJson && responseJson.errors) {
      const { token } = responseJson.errors[0]
      this.props.openToastHandler(token , "error")
    }
  };

  getNotifyValue = (val:boolean)=>{
    return val === true ? false : true
  }

  handleCheckBox = (item: NotificationData, key: string) => {
    const data = this.state.mockNotificationData.map((subItem: NotificationData) => {
      if (subItem.attributes.id === item.attributes.id) {
        return {
          ...subItem,
          attributes:{
            ...subItem.attributes,
            notifies: key === "notifies" ? this.getNotifyValue(subItem.attributes.notifies) : subItem.attributes.notifies,
            follow_up: key === "follow_up" ?  this.getNotifyValue(subItem.attributes.follow_up): subItem.attributes.follow_up
          }
         
        }

      }
      return subItem
    })
    this.setState({ mockNotificationData: data })

  };

  handleAdddays = (item: NotificationData, event: React.ChangeEvent<HTMLInputElement>) => {
    const data = this.state.mockNotificationData.map((subItem: NotificationData) => {
      if (subItem.attributes.id === item.attributes.id) {
        return {
          ...subItem,
          attributes:{
            ...item.attributes,
            days: event.target?.value
          }

        }

      }
      return subItem
    })
    this.setState({ mockNotificationData: data })
  }

  handleNavigate = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  handleAddCard = () => {
    this.handleNavigate("AddPayment");
  }
  
  handleManagePlan = () => {
    this.handleNavigate("ManagePlan");
  }
  
  handleMouseEnter = () => {
    this.setState({ showText: true });
  };

  handleMouseLeave = () => {
    this.setState({ showText: false });
  };

  handleMouseEnterNotification = () => {
    this.setState({ showTextNotification: true });
  };

  handleMouseLeaveNotification = () => {
    this.setState({ showTextNotification: false });
  };


  handleCancel = () => {
    this.props.navigation.history?.goBack();
  }

  handleGetNotification= () => {
    const header = {
      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": localStorage.getItem(configJSON.authToken),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotificationSettingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationSettingAPIEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.getAPIMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleCreateNotification= () => {
   
    const body = this.state.mockNotificationData.map((item: NotificationData) => {
      if(!this.state.firstTimeData){
        return {
          "setting_type": item.attributes.setting_type,
          "notifies": item.attributes.notifies,
          "follow_up": item.attributes.follow_up,
          "days": item.attributes.days,
        }
      } else if(this.state.firstTimeData) {
        return {
          "id": item.attributes.id,
          "setting_type": item.attributes.setting_type,
          "notifies": item.attributes.notifies,
          "follow_up": item.attributes.follow_up,
          "days": item.attributes.days,
        }
      }
     
    }) 
    const settings={
    
      settings:body
    }
    
    const header = {
      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": localStorage.getItem(configJSON.authToken),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNotificationSettingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationSettingAPIEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(settings)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     `${configJSON.exampleAPiMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End