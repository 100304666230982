Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "JobListing2";
exports.labelBodyText = "JobListing2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.updateApiMethodtype = "PATCH"
exports.deleteApiMethodType = "DELETE"
exports.storageToken = "authToken"

// Api End Points
exports.JobsEndPoint = "bx_block_request_management/jobs"
exports.markAsCompleteJob = "bx_block_request_management/mark_as_completed"
exports.jobsSearchEndPoint = "bx_block_request_management/job_search"
exports.employeesEndPoint = "account_block/employees"
exports.customersEndPoint = "account_block/customers"
exports.customerSitesEndPoint = "bx_block_location/site_informations"
exports.customerSearchEndPoint = "account_block/customer_search?query"
exports.SitSearchEndPoint = "bx_block_location//search_site?site_name"
exports.getAllSitesEndPoint = ""
exports.products_and_servicesEndPoint = "bx_block_request_management/product_and_services"
exports.getCurrency='bx_block_languagesupport/languagesupports/currency_symbol'
exports.getEmployees = "account_block/employees"
exports.getEmployeeApiEndPoint='bx_block_appointment_management/appointments/get_employees'
exports.getSingleRequestDetails = "bx_block_request_management/requests"
exports.sendEmailApiEndPoint = "bx_block_request_management/send_email"
exports.requestEndPoint="bx_block_request_management/requests"
exports.sendEmailEndPoint="bx_block_request_management/send_email"
exports.sendQuoteEmailEndPoint="bx_block_quotemanagement/send_email"
exports.quoteEndPoint="bx_block_quotemanagement/quotes"


// Static Texts
exports.jobs = {
  title: "Job Title",
  sechudling: "Date/Time Sechudling",
  team: "Team Assignee",
  products: "Product/Services",
  location:"Location",
  asentric:"*"
}

exports.sechudling = {
  oneOffJobHeading: "ONE-OFF JOB",
  oneOffSubHeading: "One or more visits",
  reoccuringJobHeading: "RECURRING JOB",
  reoccuringJobSubHeading: "Repeating visits"
}
exports.products={
  symbol:"#",
  name:"Product",
  quantityLabel:"Quantity",
  unitLabel:"Unit Price",
  totalLabel:"Total"
}
exports.saveMoreBtn={
  email:"Email Booking Confirmation",
  pdf:"Download As PDF",
  invoice:"Generate Invoice",
  quote:"Provide Quote"
}

exports.errorJobText={
  titleEmptyText:"Job Title cannot be empty",
  titleCharactersText:"Job Title contains only characters",
  titleCharacterslengthText:"Job Title can't be more than 40 characters",
  notesCharacterText:"Internal notes contains only characters" 
}

exports.errorsJobtext={
  title:{titleEmptyText:'Title cannot be Empty'},
  product_id:{titleEmptyText:'Please select The Product/Service'},
  service_id:{titleEmptyText:'Please select The Product/Service'},
}
exports.toasterText={
  created:"Job created",
  updated:"Job updated",
  deleted:"Job deleted",
  hardDeleteText:"Cancelled job deleted permanently",
  requestconverted:"Request Converted",
}
exports.storageText={
  created:"createdStatus",
  deleted:"deletedStatus",
  converted:"convertedStatus",
}
exports.jobPopupBtn={
  quote:"Convert To Quote",
  job:"Convert To Job",
  download :"Download As PDF"
}

exports.characteValidationRegex=/^[A-Za-z ]+$/
exports.emailValidation=/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
exports.errorsJobData={
  noData:"No Data Found.",
  tokenExpired:"Token has Expired",
  invalidToken:"Invalid token"
}
// Customizable Area End