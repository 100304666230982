// Customizable Area Start
import React from "react";
import {
  Container , 
  Grid , 
  Box ,
  styled ,
  InputBase,
  Dialog,
  DialogContent, 
  DialogActions,
  ClickAwayListener,
  Typography,
  Divider,
  DialogTitle ,
  DialogContentText,
  InputAdornment,
  Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Paper , Button ,IconButton , 
  Menu, MenuItem, withStyles, ListItemIcon, ListItemText,Input,Avatar
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { withSnackbar } from "../../../components/src/toastweb";
import { withTranslation } from "react-i18next";
import TimeSheetWeeklyController, {
  Props,
  TimesheetAttributes,
  configJSON,
  ToggleData,
  AttributesArray,
  ValueObject,
  EmailAccount,
} from "./TimeSheetWeeklyController";
import DashboardSideBar  from "../../dashboard/src/DashboardSideBar.web";
import CustomDatePicker from "../../../components/src/DatePicker";
import { searchIcon } from "../../CustomisableUserProfiles/src/assets";
import CloseIcon from '@material-ui/icons/Close';
import { StyledTextField } from "../../RequestManagement/src/Requests.web"
import { CONSTANTS, theme } from "../../../components/src/theme";
import moment from "moment";
import { StyledCheckbox } from "../../Settings/src/CompanyInformation.web";
import CustomDatePickers from "../../../components/src/CustomDatePicker";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { userPhoto, imgApproved , imgRejected , imgPending, imgbtnDropdown, customersIcon ,invoicesIcon } from "./assets";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TimePicker from "../../../components/src/TimePicker";
import { t } from "i18next";
import { dropDownSelectIcon } from "../../../blocks/RequestManagement/src/assets"
import {ThreedotsIcon} from "../src/assets"


interface JobsStatusData {
  statusName: string
  id: number
  title: string
}
interface JobsTableRow {
  key: string
  id: number
  title: string
}

export interface Location {
  latitude: string | null;
  longitude: string | null;
  city: string | null;
  zip_code: string | null;
  address: string | null;
}

export interface SiteAddress {
  latitude: string;
  longitude: string;
  city: string;
  state: string;
  zip_code: string;
  address: string;
}
type Accumulator = {
  [date: string]: {
      date: string;
      duration: string;
  };
};
type XterType = {
  date: string;
  duration: string;
};
export interface ProductAttributes {
  product_id: number;
  service_id: number | null;
  quantity: number;
  unit_price: string;
  currency: string;
  total: string;
  description: string;
  job_id: number;
  quote_id: number | null;
  visit_id: number | null;
  product_name: string;
  service_name:string;
}

export interface ProductItem {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

export interface Products {
  data: ProductItem[];
}

export interface JobAttributes {
  job_title: string;
  request_title?:string;
  customer_id: number;
  created_at: string;
  account_id: number;
  employee_id: number | null;
  request_id: number | null;
  frequency: number | null;
  duration: number | null;
  summary: string | null;
  every: number;
  start_date: string;
  end_date: string;
  internal_notes: string;
  site_id: number;
  status: string;
  scheduling: string;
  product_and_services: string | null;
  quote: string | null;
  employees: {name:string,img: string, mail:string}[];
  employee_image: [];
  company_logo: string | null;
  location: Location;
  site_address: SiteAddress;
  start_time: string;
  end_time: string;
  updated_at: string;
  custmoer_name: string;
  files: unknown | null;
  products: Products;
  job_id: string;
  pdf_url: string | null;
  visits: { data: [] };
  newEngStatus: string;
}

export interface Jobs {
  id: string | number;
  type: string;
  attributes: JobAttributes;
  product?: ProductItem;
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class TimeSheetWeekly extends TimeSheetWeeklyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  statusModalJob = () => {
    return (
      <ClickAwayListener onClickAway={this.handleStatusJobModalClose}>
        <Typography>
          <Box style={this.state.selectedLanguage === "ar" ? webStyle.toggleJobModalAr : webStyle.toggleJobModalContainer}
          >
            <Box style={webStyle.toggleModalStyleJob}>
              {this.state.statusToggleData.map((item: ToggleData) => (
                <Box style={webStyle.toggleIconsContainerJob} key={item.id}>
                  <StyledCheckbox
                    data-test-id="handleToggleColumnJob1"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={item.hidden}
                    onChange={() => this.handleJobSelectStatus(item)}
                  />
                  <MenuItem
                    className="tableText"
                    value={item.title}
                    disableRipple
                  >
                    {item.title}
                  </MenuItem>
                </Box>
              ))}
            </Box>
          </Box>
        </Typography>
      </ClickAwayListener>
    );
  }
  /* istanbul ignore next */
  editDialog = () => {
    return <>
   {  this.state.editTime && (
        <CustomTimeSheet
        open={this.state.editTime}
        onClose={this.handleClose}
        data-test-id="custom_TimeSheet" 
      >
        <div className="display">   
        <DialogTitle style={webStyle.textlg}>{t('boEmployees.editTimesheet')}</DialogTitle>
        
      </div>
        <DialogContent>
        
          <DialogContentText style={webStyle.textsm}>
          <Grid container spacing={2} className="spacings">
          <Grid item xs={6} sm={6} md={6} className="spacing">
          <span>{t('Calendar.label.start')}*</span>
                <TimePicker
                  time={this.state.startTime}
                  data-test-id="change_time"
                  placeholder="00:00 AM"
                  onChange={(timeValue: string) => this.handleChangeHours(timeValue)}
                  timeFormat={this.state.timeFormat}
                  t={t}
                  languageSelected={this.state.selectLanguage}/>
            </Grid>
            <Grid item xs={6} sm={6} md={6} className="spacing">
              <span>{t('Calendar.label.end')}*</span>
                <TimePicker
                  time={this.state.endTime}
                  data-test-id="change_endtime"
                  placeholder="00:00 PM"
                  onChange={(timeValue: string) => this.handleChangeHoursendTime(timeValue)}
                  timeFormat={this.state.timeFormat}
                  t={t}
               languageSelected={this.state.selectLanguage}
                                                    />
            </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button onClick={this.handleClose} data-test-id="btncli" className="btnCancelPopup">
          {t('boEmployees.cancel')}
          </Button>
          <Button onClick={ async() => {
            const data = this.state.timeSheetState.find((item) => item.id === this.state.editId)
            const allData= this.state.timeSheetState.filter((item:TimesheetAttributes) => item.attributes.associate_id === data?.attributes.associate_id)
            await Promise.all(allData?.map((item:TimesheetAttributes) => this.handleSave(item.id)))
            }} className="btnDeletePopup" data-test-id="btnclicks_times">
            {t('boEmployees.save')}
          </Button>
        </DialogActions>
      </CustomTimeSheet>

      )}
    </>
  }
  jobsViewData = ({ keys, item }: { keys: string, item: Jobs | ValueObject }) => {
    return <>

        <Grid item
          xs={4}
          className={"jobCellLayout"}
          onClick={this.renderHandleViewJob.bind(this, item)}
          data-test-id="viewData"
        >
          <Typography variant="body1" className="tableText" >

            {item.attributes.job_title}
          </Typography>
        </Grid>

        <Grid item
          xs={4}
          className={"jobCellLayout"}
          onClick={this.renderHandleViewJob.bind(this, item)}
          data-test-id="viewData1"
        >
          <Typography variant="body1" className="tableText" >
            {item.attributes.custmoer_name}
          </Typography>
        </Grid>

        <Grid item
          xs={4}
          className={"jobCellLayout"}
          onClick={this.renderHandleViewJob.bind(this, item)}
        >
          <Typography variant="body1" className="tableText" >
            {moment(item?.attributes.start_date).format("DD/MM/YYYY").toString()} - {moment(item?.attributes.end_date).format("DD/MM/YYYY").toString()}
          </Typography>
        </Grid> 

    </>
  }

  requestsViewData = ({ keys, item }: { keys: string, item: Jobs | ValueObject }) => {
    return <>

        <Grid item
          id="request-1"
          xs={4}
          className={"jobCellLayout"}
          onClick={this.renderHandleViewJob.bind(this, item)}
          data-test-id="viewData"
        >
          <Typography id="request-2" variant="body1" className="tableText" >

            {item.attributes.request_title}
          </Typography>
        </Grid>

        <Grid item
        id="request-3"
          xs={4}
          className={"jobCellLayout"}
          onClick={this.renderHandleViewJob.bind(this, item)}
          data-test-id="viewData1"
        >
          <Typography id="request-4" variant="body1" className="tableText" >
            {item.attributes.custmoer_name}
          </Typography>
        </Grid>


        <Grid item
          xs={4}
          className={"jobCellLayout"}
          onClick={this.renderHandleViewJob.bind(this, item)}
        >
          <Typography variant="body1" className="tableText" id="request-5" >
            {moment(item?.attributes.start_date).format("DD/MM/YYYY").toString()} - {moment(item?.attributes.end_date).format("DD/MM/YYYY").toString()}
          </Typography>
        </Grid> 

    </>
  }

  jobsTableRow = (mockJobsTableRow: JobsTableRow[]) => {
    return <>
      {mockJobsTableRow.map((item: JobsTableRow, index: number) => (
        <>
              <Grid item
                className={"headLayout"}
                key={item.id}
                onClick={() => this.handleSortJob(item.key)}
                data-test-id="handleSort"
                xs={4}
              >
                <span
                  style={webStyle.jobTableHeadSpan}
                >
                  <Typography variant="body1" align="left"
                    className="tableText"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {item.title}
                  </Typography>
                </span>
              </Grid>
        </>
      ))}
    </>
  }
  
  jobHeadings = (keyValue: string, jobStatusData: JobsStatusData[]) => {
    const { textColor, dividerColor } = this.getJobHeadingStyle(keyValue)
    return (
      <>
        <Grid container>
          <Typography variant="body2" className={keyValue === "upcoming" ?
            "statusKeysTextPending" : this.findClassesJobs(keyValue)} style={{ color: textColor }}>
            {keyValue === 'in_progress' && `${this.props.t('jobs.statusMenu.inProgress')}` || keyValue === 'late' && `${this.props.t('jobs.statusMenu.overdue')}` || keyValue === "to_be_invoiced" && `${this.props.t('jobs.statusMenu.invoiced')}` ||
              jobStatusData?.find((item) => item.statusName === keyValue)?.title
            }
          </Typography>
        </Grid>
        <Divider style={keyValue === "upcoming" ? { ...webStyle.dividerColorPending, backgroundColor: dividerColor } :
          { ...this.findDividerColorJobs(keyValue), backgroundColor: dividerColor }} />
      </>
    )
  }

  requestHeadings = (keyValue: string, jobStatusData: JobsStatusData[]) => {
    const { textColor, dividerColor } = this.getRequestHeadingStyle(keyValue)
    return (
      <>
        <Grid container  id="request-7" >
          <Typography  id="request-8" variant="body2" className={keyValue === "pending" ?
            "statusTextPending" : this.findClassesJobs(keyValue)} style={{ color: textColor }}>
            {keyValue === 'in_progress' && `${this.props.t('jobs.statusMenu.inProgress')}` || keyValue === 'late' && `${this.props.t('jobs.statusMenu.overdue')}` || keyValue === "to_be_invoiced" && `${this.props.t('jobs.statusMenu.invoiced')}` ||
              jobStatusData?.find((item) => item.statusName === keyValue)?.title
            }
          </Typography>
        </Grid>
        <Divider  id="request-9" style={keyValue === "upcoming" ? { ...webStyle.dividerColorPending, backgroundColor: dividerColor } :
          { ...this.findDividerColorJobs(keyValue), backgroundColor: dividerColor }} />
      </>
    )
  }

  findDividerColorJobs = (keys: string) => {
    return keys === "in_progress" ? webStyle.dividerLine : this.findDividerColorJobsV1(keys)
  }

  findDividerColorJobsV1 = (keys: string) => {
    return keys === "completed" ? webStyle.dividerColorQuoted : this.findDividerColorJobsV2(keys)
  }

  findDividerColorJobsV2 = (keys: string) => {
    return keys === "to_be_invoiced" ? webStyle.dividerColorConverted : this.findDividerColorJobsV3(keys)
  }

  findDividerColorJobsV3 = (keys: string) => {
    return keys === "late" ? webStyle.dividerColorOverdue : webStyle.dividerColorCancelled
  }
  //istanbul ignore next
  buttonallSelect = (items:  TimesheetAttributes) => {

     return (
       <>
           {items.attributes.status === 'approved' && <Button data-test-id="approvedStatus" onClick={()=>this.employeeeStatus(items.id)} className="btnApproved">
      <img src={imgApproved} style={webStyle.imgButton} alt="Approved" />{t('quote.approved')} <img src={imgbtnDropdown} style={webStyle.imgButtonDropdown} alt="Approved" /></Button>}
      {items.attributes.status === 'pending' && <Button data-test-id="pendingStatus" onClick={()=>this.employeeeStatus(items.id)} className="btnPending">
      <img src={imgPending} style={webStyle.imgButton} alt="Pending" />{t('quote.pending')} <img src={imgbtnDropdown} style={webStyle.imgButtonDropdown} alt="Pending" /></Button>}
      {items.attributes.status === 'rejected' && <Button data-test-id="rejectStatus" onClick={()=>this.employeeeStatus(items.id)} className="btnRejected">
      <img src={imgRejected} style={webStyle.imgButton} alt="Rejected" />{t('timesheet.rejected')} <img src={imgbtnDropdown} style={webStyle.imgButtonDropdown} alt="Rejected" /></Button>}
       </>
     )
  } 
  btnJobRequest = (selectedType:string) => {
    return(
      <>
        <Button
          variant="contained"
          onClick={() => this.handleTypeChange("Job")}
          className={selectedType === "Job" ? "selectedBtn" : "unSelectBtn"}
          data-test-id="job-switch"
        >
         {t('dashboardSidebar.job')}
        </Button>
        <Button
          variant="contained"
          onClick={() => this.handleTypeChange("Request")}
          className={selectedType === "Request" ? "selectedBtn" : "unSelectBtn"}
          data-test-id="request-switch"
        >
          {t('dashboardSidebar.request')}
        </Button>
      </>
    )
  }

  selectEmp = () => {
    return(
   
      <div className="btnContainer">
      {this.state.role === "false" && (
          <>
            <CustomBtnTimeSheet>
              <Button
                className="timesheetBtncontainer" style={this.state.timeSheetStatus === "Time" ? webStyle.BtnBigWhite : webStyle.BtnBig}
                data-test-id="nextstatus"
                onClick={() => this.onClickStatusChange("Time")}
              ><img className="imgAlign" style={webStyle.imageIcon2} src={invoicesIcon}></img>
                <span>{t('timesheet.mytimesheetButon')}</span> 
              </Button>
              <Button
                className="EmptimesheetBtncontainer"
                style={this.state.timeSheetStatus === "Employee" ? webStyle.BtnBigWhite : webStyle.BtnBig}
                data-test-id="pervstatus"
                onClick={() => this.onClickStatusChange("Employee")}
              ><img  className="imgEmpAlign" style={webStyle.imageIcon} src={customersIcon}></img>
                <span>{t('timesheet.employeeTimesheet')}</span> 
              </Button>
              </CustomBtnTimeSheet>
          </>
      )}
    
    </div>
    )
  }

  dateSelected = () => {
    return(this.dateSelectWeek(this.state.selectDate).map((days, index) => (
      <TableCell align="center" key={index}><Box>
        <Box style={{ color: this.curretDate() === days.format('YYYY-MM-DD') ? "blue" : "" }}>{days.format('ddd')}</Box>
        <Box style={{ color: this.curretDate() === days.format('YYYY-MM-DD') ? "blue" : "" }}>{days.format('DD')}</Box>
      </Box></TableCell>
    )))
  }
  showSave=()=>{
    return (
      <>
    {this.state.saveButton && (
      <StyledBox2 item 
   
      className="btnSaveDrid"
      style={webStyle.saveEntryButton}>
        <Button
        className="btnSave"
        data-test-id = "createDataTimesheet"
          style={webStyle.buttonSave as React.CSSProperties}
          onClick={this.createDataTimesheet}
          
        > {t('boProfile.save')} </Button>
      </StyledBox2>
          )}
          </>
    )
  }
 
  showaddEntries=()=>{
    return(
      <>
        {this.state.addToggle && (
          <Button
            className="buttonAdd"
            onClick={this.handleAddEntriesClick}
            data-test-id="handleAddEntriesClick"
          > + {t('timesheet.addEntriesButton')} </Button>
        )}
      </>
    )
  }
  returnTableData = () => {
    return (
      <>
        {this.state.timeSheetState.map((items: TimesheetAttributes, index: number) => {

          const arrIndex = this.state.timeSheetState.slice(0, index).findIndex((element: TimesheetAttributes) => element?.attributes?.associate_id === items?.attributes?.associate_id)

          if (arrIndex === -1) {
            const dataArr = this.state.timeSheetState.filter((elem: TimesheetAttributes) => elem?.attributes?.associate_id === items?.attributes?.associate_id).map((alle: TimesheetAttributes) => ({ date: alle?.attributes?.date, duration: alle?.attributes?.duration }))
            function convertToMinutes(time: string) {
              const [hours, minutes] = time.split(':').map(Number);
              return hours * 60 + minutes;
            }
            const totalDurationInMinutes = dataArr.reduce((total, current) => {
              return total + convertToMinutes(current.duration);
            }, 0);

            function convertToTime(minutes: number): string {
              const hours = Math.floor(minutes / 60);
              const mins = minutes % 60;
              return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
            }
            Object.values(
              dataArr.reduce((acce: Accumulator, { date, duration }) => {
                if (!acce[date]) {
                  acce[date] = { date, duration: "00:00" };
                }
                const currentDurationInMinutes = convertToMinutes(acce[date].duration);
                const newDurationInMinutes = currentDurationInMinutes + convertToMinutes(duration);
                acce[date].duration = convertToTime(newDurationInMinutes);
                return acce;
              }, {})
            );
            const totalHours = Math.floor(totalDurationInMinutes / 60);
            const totalMinutes = totalDurationInMinutes % 60;
            const totalDuration = `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}`;
            return (
              <TableRow key={index}>
                <TableCell className="textStart" key={index}>{items.attributes.customer_name}</TableCell>
                {this.dateSelectWeek(this.state.selectDate).map((days, indexs) => {
                  const datese = dataArr.find((xter: XterType) => xter?.date === days.format('YYYY-MM-DD'));
                  return (
                    <TableCell align="center" key={indexs}>{datese ? datese?.duration : "00:00"}</TableCell>
                  )
                })}
                <TableCell align="center" key={index}>{totalDuration}</TableCell>
                {!this.state.addToggle &&
                <TableCell align="center" data-test-id="handleClickss" onClick={this.handleClickss}>
                  {this.buttonallSelect(items)}
                </TableCell>}
                 <TableCell>
                  <IconButton data-test-id="clickIcon" onClick={(event) => this.handleClick(event, items.id)}>
                  <img style={webStyle.threeIcon} src={ThreedotsIcon}></img>
                  </IconButton>

                </TableCell>
              </TableRow>
            )
          }
        })}

      </>
    )
  }
  roleEmployee = () => {
    return (
      <>
      {this.state.role === "false" ?
      <Grid className="Widthtop">
      {this.selectEmp()}
      </Grid>  : 
      <Grid>
      {this.selectEmp()}
      </Grid>}
      </>
    )
  }
  employeeContent = () => {
    return(
      <>
      <Grid item xs={12} sm={3} style={webStyle.userDetailBo}>
    <Avatar src={this.state.empImg} style={webStyle.userPhoto} alt="User" />
    <div className="widthfullName">
      <p>{this.state.employeeName}</p>
      <p>{this.state.employeeEmail}</p>
    </div>
  </Grid>
      </>
    )
  }
  /* istanbul ignore next */
  timeSheetContent = () => {
    return(
      <>
       <Box style={webStyle.searchContainer}>
    <Autocomplete
      id="free-solo-2-demo"
      disableClearable
      style={webStyle.searchInput}
      fullWidth
      data-test-id="searchInput"
      onChange={(event, newValue) => this.handleSearchTimeSheet(newValue)}
      value={this.state.selectedEmployee as EmailAccount}
      options={this.state.employeeState}
      getOptionLabel={(option) => `${option?.attributes.full_name}`}
      renderOption={(option) => (
        <React.Fragment>
          <Grid item xs={12} sm={3} style={webStyle.userDetails}>
            <Avatar src={option?.attributes?.employee_image?.url} style={webStyle.userPhotosm} alt="User" />
            <div className="widthfull">
              <p style={webStyle.fontSm}>{option?.attributes.full_name}</p>
              <p style={webStyle.fontSm}>{option?.attributes.email}</p>
            </div>
          </Grid>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          placeholder={`${t('reports.searchEmployee')}`}
          fullWidth
          InputProps={{
            ...params?.InputProps,
            type: 'search',
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <img style={webStyle.searchIconRequest} src={searchIcon} alt="" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  </Box>
      </>
    )
  }
  /* istanbul ignore next */
  alternativeContent = () => {
    return(
      <>
       <Grid item xs={12} sm={3} style={webStyle.userDetails}>
    <Avatar src={this.state.boProfilImage} style={webStyle.userPhoto} alt="User" />
    <div className="widthfullName">
      <p>{this.state.boName} {this.state.boLastName}</p>
      <p>{this.state.employeeEmail}</p>
    </div>
  </Grid>
      </>
    )
  }
  // istanbul ignore next
  addCondition = () => {
    return (
      this.state.timeSheetStatus !== "Time" ? this.timeSheetContent() : this.alternativeContent()
    )
  }
  userDetailsSection=()=>{
   return( 
     this.state.role === "false" ?  this.addCondition() :this.employeeContent()
    )
  }
  statusCOl=()=>{
   return !this.state.addToggle && <TableCell align="center">{t('dashboard.status')}</TableCell>
  }
  jobReqBtn=()=>{
  return this.state.showSelectJobRequestButton  && (
      <Button
        className="textSize"
        data-test-id="handleOpenJobRequestDialog"
        onClick={this.handleOpenJobRequestDialog}
      >
        {t('timesheet.jobRequestButton')}
      </Button>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { anchorEl , showSelectJobRequestButton, openJobRequestDialog , selectedType , anchorEls,selectedLanguage } = this.state;
    const { t } = this.props;
    const statusMenuStyle = () => {
      const styleMapping  :{[key: string]: React.CSSProperties }= {
        true: webStyle.transform,
        false: webStyle.dropDownSelectIcon,
      };
    
      return styleMapping[this.state.statusModal.toString()]
    }

    const jobStatusData = [
        { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
        { id: 2, title: `${t('jobs.statusMenu.upcoming')}`, statusName: "upcoming" },
        { id: 3, title: `${t('jobs.statusMenu.inProgress')}`, statusName: "in_progress" },
        { id: 4, title: `${t('jobs.statusMenu.invoiced')}`, statusName: "invoiced" },
        { id: 5, title: `${t('jobs.statusMenu.completed')}`, statusName: "completed" },
        { id: 6, title: `${t('jobs.statusMenu.late')}`, statusName: "late" },
        { id: 7, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
    ]
    const mockJobsTableRow = [
        { id: 1, key: "job_title", title: `${t('jobs.tableRow.title')}` },
        { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
        { id: 3, key: "start/end_date", title: `${t('jobs.tableRow.startEndDate')}` },
    ]
    const mockRequestsTableRow = [
      { id: 1, key: "request_title", title: `${t('request.title')}` },
      { id: 2, key: "customer_name", title: `${t('invoice.menuLabel.cusName')}` },
      { id: 3, key: "start/end_date", title: `${t('jobs.tableRow.startEndDate')}` },
  ]

    const mockRequestsStatusData = [
      { id: 1, title: `${t('request.statusMenu.all')}`, statusName: "all" },
      { id: 2, title: `${t('request.statusMenu.pending')}`, statusName: "pending" },
      { id: 3, title: `${t('request.statusMenu.quoted')}`, statusName: "quoted"},
      { id: 4, title: `${t('request.statusMenu.convertedToJob')}`, statusName: "converted_to_job"  },
      { id: 5, title: `${t('request.statusMenu.overdue')}`, statusName: "overdue" },
      { id: 6, title: `${t('request.statusMenu.cancelled')}`, statusName: "cancelled" },
    ]
      // istanbul ignore next
    return (
      <>
      <Box style={{overflow: selectedLanguage === "ar" ? "hidden" : "scroll"}}>
        <DashboardSideBar id={""} permissions={undefined} oldProps={undefined} history={undefined} {...this.props} navMenuItem={this.props.t('dashboardNavbar.timesheet')} active={9} active1={false} active2={false} />

        <CustomFormWeekly>
          <Box 
          className={selectedLanguage === "ar" ? "boxMainWeekly" : "boxResponse"}
          >
            <Grid container spacing={3} >
            <h3 className="padingresponsive">{t('timesheet.timesheetSettings')}</h3>
            </Grid>
            {this.roleEmployee()}
           
            
            <Grid className="boarderBott">

            </Grid>
            <Grid container style={webStyle.mainGrid} className="gridrespo">
              <Grid item xs={12} sm={3} style={webStyle.userDetails}>
              {this.state.selectedEmployee &&  this.state.timeSheetStatus!=="Time" &&
                <Grid item xs={12} sm={3} style={webStyle.userDetails}>
                <Avatar src={this.state.selectedEmployee.attributes?.employee_image?.url} style={webStyle.userPhoto} alt="User" />
                <div className="widthfull">
                  <p>{this.state.selectedEmployee.attributes.full_name}</p>
                  <p>{this.state.selectedEmployee.attributes.email}</p>
                </div>
              </Grid>
              }
               {this.userDetailsSection()}
              </Grid>
              <Grid item xs={12} sm={6} style={webStyle.controls}>
                <span style={webStyle.arrowIcon} data-test-id="handleClickPrevDate" onClick={() => this.handleClickPrevDate()}>{"<"}</span>
                <Button className="button">{t('timesheet.todayButton')}</Button>
                <StyledDatePickerWrapper style={webStyle.datePickerContainer}>
                  <CustomDatePicker selectedDate={this.state.selectDate} className="spacingSmall" data-test-id="dateChange" dateChange={this.dateChange} />
                  <span style={webStyle.arrowIcon} data-test-id="handleClickNextDate" onClick={() => this.handleClickNextDate()}>{">"}</span>
                </StyledDatePickerWrapper>
                <Button className="buttonWeekly" data-test-id="dailyPage" onClick={() => this.goToDailyPage()}>{t('timesheet.dailyButton')}</Button>
                <Button className="button" onClick={() => this.goToWeekly()}>{t('timesheet.weeklyButton')}</Button>
              </Grid>
              <Grid item xs={12} sm={6} style={webStyle.addEntryButton}>      

              {this.showaddEntries()}          
              
              </Grid>
            </Grid>
            
            <Container maxWidth={false}>
              <TableContainer component={Paper} style={webStyle.tableContainerr as React.CSSProperties}>
                <Table aria-label="weekly table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="textStart">{this.dateFormateFunction(this.state.selectDate)}</TableCell>
                      {this.dateSelected()}
                      <TableCell align="center">{t('timesheet.totalHrs')}</TableCell>
                      {this.statusCOl()}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.jobReqBtn()}
                    
                    {Array.from({ length: this.state.additionalRows }, (_, index) => (
                      <React.Fragment key={`additional-${index}`}>


                        <TableRow>
                        <TableCell className="textStart" key={index}>{this.state.customerName}</TableCell>
                          {this.dateSelectWeek(this.state.selectDate).map((days, indexs) => (
                            <TableCell align="center" key={indexs}><CustomInput
                              className="addInputText"
                              data-test-id="handleHoursDuration"
                              placeholder="00.00"
                              onChange={(event) => this.handleHoursDurations(event, indexs)}
                            /></TableCell>

                          ))}
                          <TableCell align="center" key={index}><CustomInput
                          className="addInputText"
                           placeholder="00.00" 
                           value={this.state.totalHours}                       
                        
                           disableUnderline
                          />
                         
                          </TableCell>

                          {localStorage.getItem("timeSheetStatus")==="Employee"&& <TableCell align="center" data-test-id="handleClicksser" onClick={this.handleClickss}>
                          <Button data-test-id="pendingStatused" onClick={()=>this.employeeeStatus(String(this.state.jobAsscociateId))} className="btnPending">
      <img src={imgPending} style={webStyle.imgButton} alt="Pending" />{t('quote.pending')} <img src={imgbtnDropdown} style={webStyle.imgButtonDropdown} alt="Pending" /></Button>
                          </TableCell>}
                          <TableCell>
                            <IconButton data-test-id="clickIcon" >
                            <img style={webStyle.threeIcon} src={ThreedotsIcon}></img>
                            </IconButton>

                          </TableCell>

                        </TableRow>

                      </React.Fragment>
                    ))}           
                     { this.returnTableData()}
                                        
                  
                    {this.state.timeSheetState.length ? <TableRow>
                      <TableCell className="hrs_FontWeight">
                       {t('timesheet.totalHrs')}:
                      </TableCell>
                      {this.dateSelectWeek(this.state.selectDate).map((days, index) => (
                        <TableCell align="center" key={index} className="hrs_FontWeight">
                          <Box>
                            {this.TotalHoursCountFunction(days.format('YYYY-MM-DD'))}
                          </Box>
                        </TableCell>
                      ))}
                      <TableCell></TableCell>
                      {!this.state.addToggle &&
                      <TableCell><Button className="btnApproveAll"><img src={imgApproved} style={webStyle.imgButtonApprove} alt="Approved" />Approve All</Button></TableCell>
                      }
                      <TableCell></TableCell>
                    </TableRow> : ""}
                  </TableBody>

                  <StyledRoot>
                  </StyledRoot>
                </Table>
              </TableContainer>     
             {this.showSave()}
              <CustomMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: '20ch',
                    borderRadius: 10
                  },
                }}
              >
                <li className="main-title-menu">{t('Calendar.button.action')}</li>
                <StyledMenuItem data-test-id="handleClose" onClick={() => this.handleEdit()}>
                  <ListItemIcon>
                    <EditOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('boEmployees.edit')} />
                </StyledMenuItem>
                <StyledMenuItem  data-test-id="handleDeleteId" onClick={()=>this.handleDeleteId()}>
                  <ListItemIcon >
                    <DeleteOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('boEmployees.delete')} />
                </StyledMenuItem>
              </CustomMenu>


              <CustomMenu2
                anchorEl={anchorEls}
                open={Boolean(anchorEls)}
                onClose={this.handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: '20ch',
                    borderRadius: 10
                  },
                }}
              >
             
                <li className="main-title-menu">{t('dashboard.status')}</li>
                <MenuItem data-test-id="employeeStatus" onClick={async ()=>{
                  const findIds = this.state.timeSheetState.find((item) => item.id === this.state.editId)?.attributes?.associate_id
                  const allIds = this.state.timeSheetState.filter((elem) => elem.attributes.associate_id === findIds).map((item) => item?.id)
                  Promise.all(allIds.map((elem) => this.handleEmployeeeStatus("rejected" , elem)))
                  
                  }} className="colRejected"><img src={imgRejected} style={webStyle.imgButton} alt="Approved" /> {t('timesheet.rejected')}</MenuItem>
                <MenuItem data-test-id="employeeStatus1" onClick={async ()=>{
                  const findIds = this.state.timeSheetState.find((item) => item.id === this.state.editId)?.attributes?.associate_id
                  const allIds = this.state.timeSheetState.filter((elem) => elem.attributes.associate_id === findIds).map((item) => item?.id)
                  Promise.all(allIds.map((elem) => this.handleEmployeeeStatus("pending" , elem)))
                 
                  }} className="colpending"> <img src={imgPending} style={webStyle.imgButton} alt="Approved" />{t('quote.pending')}</MenuItem>
                <MenuItem data-test-id="employeeStatus2" onClick={async ()=>{
                  const findIds = this.state.timeSheetState.find((item) => item.id === this.state.editId)?.attributes?.associate_id
                  const allIds = this.state.timeSheetState.filter((elem) => elem.attributes.associate_id === findIds).map((item) => item?.id)
                  Promise.all(allIds.map((elem) => this.handleEmployeeeStatus("approved" , elem)))
                 
                  }}  className="colapprove"><img src={imgApproved} style={webStyle.imgButton} alt="Approved" />{t('quote.approved')}</MenuItem>
              </CustomMenu2>
            </Container> 
                   
          </Box>          
        </CustomFormWeekly>
        <CustomDialog
           className="popupwidth"
          open={openJobRequestDialog}
          onClose={this.handleCloseJobRequestDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <Grid className="buttonstyle">
              <Grid>
                {this.btnJobRequest(selectedType)}
              </Grid>
              <Grid>
                <IconButton onClick={this.handleCloseJobRequestDialog}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
             
            {selectedType === "Job" && (
              <StyledRoot>
              <Box
                  className={"boxJobContainer"}
              >
                  <Box>
                      <Grid container spacing={1}>
                          <Grid item  sm={6}
                          lg={6} md={6}
                          >
                              <Box style={webStyle.backIconContainer}>
                                  <Box style={webStyle.jobSearchContainer}>
                                      <Box>
                                          <img style={webStyle.jobSearchIcon} src={searchIcon} alt="" />
                                      </Box>

                                      <InputBase
                                          data-test-id="searchJob"
                                          style={webStyle.jobInputBase}
                                          placeholder={`${t('jobs.placeholder.searchJob')}`}
                                          inputProps={{ 'aria-label': 'search' }}
                                          value={this.state.searchJobs}
                                          onChange={this.handleChangeSearchJobs}
                                      />
                                  </Box>
                              </Box>
                          </Grid>
                          <Grid item sm={3}
                          lg={3} md={3}
                          >
                              <CustomDatePickers
                                  data-test-id="custom-date-pickers"
                                  type='Job'
                                  selectedDate={this.state.selectedDate}
                                  dateModal={this.state.dateModal}
                                  status={this.state.dateStatus}
                                  languageSelected={this.state.selectedLanguage}
                                  datePickerRef={this.datePickerRef}
                                  handleSelectStatus={this.handleSelectDateStatus}
                                  handleDateChange={this.handleDateChange}
                                  handleStatusModalClose={this.handleStatusModalClose}
                                  handleDateModalOpen={this.handleDateModalOpen}
                                   />
                          </Grid>
                          <Grid item 
                           lg={3} md={3}
                          sm={3}>
                              <StyledTextField
                                  data-test-id="dateModalOpen"
                                  fullWidth
                                  defaultValue={`${t('invoice.placeholder.status')}`}
                                  variant="outlined"
                                  InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                          <InputAdornment position="end">
                                          <img
                                            style={statusMenuStyle()}
                                            src={dropDownSelectIcon} alt="" />
                                          </InputAdornment>
                                      ),
                                  }}
                                  onClick={this.handleStatusJobModalOpen}
                              >
                              </StyledTextField>
                              {this.state.statusModal ? (
                                  this.statusModalJob()
                              ) : null}
                          </Grid>
                      </Grid>

                      <StyledBox container>
                          <Grid item xs={12} style={webStyle.overflows}>
                              <Grid container
                              style={webStyle.gridcont as React.CSSProperties}
                              >
                                  {this.jobsTableRow(mockJobsTableRow)}
                              </Grid>
                              <Divider className={"marginTop_1"} />
                              {Object.keys(this.state.allJobs)?.map((keys: string) => {
                                  return (
                                      <>
                                          {this.jobHeadings(keys, jobStatusData)}

                                          {(this.state.allJobs as Record<string,AttributesArray>)[keys]?.data?.length === 0 &&
                                              <Grid container 
                                              style={webStyle.Centerdata}
                                               
                                              >
                                                  < Grid item xs={12}>
                                                      <Typography variant="subtitle1" className={"emptyData"} align="center">
                                                          {t('ProductService.noDataFound')}.
                                                      </Typography>
                                                  </Grid>
                                              </Grid>
                                          }
                                          {(this.state.allJobs as Record<string,AttributesArray>)[keys]?.data?.length !== 0 &&
                                              <Grid container style={webStyle.gridHeigt
                                              }>
                                                  <Grid item xs={12}>
                                                      {(this.state.allJobs as Record<string,AttributesArray>)[keys]?.data?.map((item: Jobs | ValueObject) => (
                                                          <>
                                                              <Grid container
                                                                  style={webStyle.cont as React.CSSProperties }
                                                                  data-test-id="moveHover"
                                                                  key={item.id}
                                                              >
                                                                  {this.jobsViewData({ keys, item })}
                                                              </Grid>
                                                              <Divider />
                                                          </>
                                                      ))}
                                                  </Grid>
                                              </Grid>
                                          }
                                      </>
                                  )
                              })}
                          </Grid>
                      </StyledBox>
                  </Box>
              </Box>
          </StyledRoot>
          
            )}
              <LoadingBackdrop
                    loading={this.state.getJobsLoading}
                />
            {selectedType === "Request" && (
              <StyledRoot>
              <Box id="request-10" 
                  className={"boxJobContainer"}
              >
                  <Box id="request-11" >
                      <Grid container spacing={1} id="request-12" >
                          <Grid item sm={6}
                          lg={6} md={6}
                          id="request-13" >
                              <Box style={webStyle.backIconContainer} id="request-14" >
                                  <Box style={webStyle.jobSearchContainer} id="request-15" >
                                      <Box>
                                          <img style={webStyle.jobSearchIcon} src={searchIcon} alt="" id="request-16"  />
                                      </Box>

                                      <InputBase id="request-17" 
                                          data-test-id="searchJob"
                                          style={webStyle.jobInputBase}
                                          placeholder={`${t('jobs.placeholder.searchJob')}`}
                                          inputProps={{ 'aria-label': 'search' }}
                                          value={this.state.searchJobs}
                                          onChange={this.handleChangeSearchJobs}
                                      />
                                  </Box>
                              </Box>
                          </Grid>
                          <Grid item sm={3} lg={3} md={3}
                          id="request-18" >
                              <CustomDatePickers
                                  data-test-id="custom-date-pickers"
                                  type='Request'
                                  selectedDate={this.state.selectedDate}
                                  dateModal={this.state.dateModal}
                                  status={this.state.dateStatus}
                                  languageSelected={this.state.selectedLanguage}
                                  datePickerRef={this.datePickerRef}
                                  handleStatusModalClose={this.handleStatusModalClose}
                                  handleDateModalOpen={this.handleDateModalOpen}
                                  handleSelectStatus={this.handleSelectDateStatus}
                                  handleDateChange={this.handleDateChange}
                                   />
                          </Grid>
                          <Grid item sm={3} lg={3} md={3} 
                          id="request20" >
                              <StyledTextField id="request-21" 
                                  data-test-id="dateModalOpen"
                                  fullWidth
                                  defaultValue={`${t('invoice.placeholder.status')}`}
                                  variant="outlined"
                                  InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                          <InputAdornment position="end" id="request-22" >
                                             <img
                                            style={statusMenuStyle()}
                                            src={dropDownSelectIcon} alt="" />
                                          </InputAdornment> 
                                      ),
                                  }}
                                  onClick={this.handleStatusJobModalOpen}
                              >
                              </StyledTextField >
                              {this.state.statusModal ? (
                                  this.statusModalJob()
                              ) : null}
                          </Grid>
                      </Grid>

                      <StyledBox container id="request-23" >
                          <Grid item xs={12} style={webStyle.overflows} id="request-24" >
                              <Grid container id="request-25" 
                              style={webStyle.gridcont as React.CSSProperties}
                              >
                                  {this.jobsTableRow(mockRequestsTableRow)}
                                  
                              </Grid>
                              <Divider className={"marginTop_1"} />
                              {Object.keys(this.state.allrequest)?.map((keys: string) => {
                                  return (
                                      <>
                                          {this.requestHeadings(keys, mockRequestsStatusData)}

                                          {(this.state.allrequest as Record<string,AttributesArray>)[keys]?.data?.length === 0 &&
                                              <Grid container style={webStyle.Centerdata} id="request-26" 
                                               
                                              >
                                                  < Grid item xs={12} id="request-7" >
                                                      <Typography variant="subtitle1" className={"emptyData"} align="center" id="request-27" >
                                                          {t('ProductService.noDataFound')}.
                                                      </Typography>
                                                  </Grid>
                                              </Grid>
                                          }
                                          {(this.state.allrequest as Record<string,AttributesArray>)[keys]?.data?.length !== 0 &&
                                              <Grid container style={webStyle.gridHeigt
                                              }>
                                                  <Grid item xs={12} id="request-28" >
                                                      {(this.state.allrequest as Record<string,AttributesArray>)[keys]?.data?.map((item: Jobs | ValueObject) => (
                                                          <>
                                                              <Grid container id="request-29" 
                                                                  style={webStyle.cont as React.CSSProperties }
                                                                  data-test-id="moveHover"
                                                                  key={item.id}
                                                              >
                                                                  {this.requestsViewData({ keys, item })}
                                                              </Grid>
                                                              <Divider />
                                                          </>
                                                      ))}
                                                  </Grid>
                                              </Grid>
                                          }
                                      </>
                                  )
                              })}
                          </Grid>
                      </StyledBox>
                  </Box>
              </Box>
          </StyledRoot>
            )}
          </DialogContent>
        </CustomDialog>
        {this.editDialog()}
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  overflows: {
    overflow: 'scroll', 
    minWidth: "fit-content"
  },
  cont: {
    display: 'flex',
    flexWrap: 'nowrap',
    zIndex: 0,
    width: '100%',
    gridAutoFlow: 'column',
    position: 'relative',
    justifyContent: 'end'
  },
  mainGrid: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0",
  },
  userPhoto: {
    width: "41px",
    height: "41px",
    margin :"0 10px 0 0"
  },
  userPhotosm:{
    width: "28px",
    height: "28px",
    margin :"0 10px 0 0"
  },
  fontSm:{
    fontSize:"16px"
  },
  userDetails: {
    display: "flex",
    alignItems: "center",
  },
  userDetailBo: {
    display: "flex",
    alignItems: "center",
    minwidth:"690px"
  },
  controls: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
  },
  button: {
    backgroundColor: "#1C18AF",
    padding: "10px 20px",
    color: "white",
    borderRadius: "5px",
    margin: "0 5px",
    border: "none",
    height: "48px",
    cursor: "pointer",
    outline: "none",
    textTransform: "none" as const
  },
  buttonAdd: {
    color: "white",
    backgroundColor: "#1C18AF",
    borderRadius: "5px",
    padding: "10px 20px",
    border: "none",
    margin: "0 5px",
    cursor: "pointer",
    outline: "none",
    height: "48px",
    fontWeight:500,
    fontSize:"14px",
    textTransform: "none" as const
  },
  buttonSave:{
    backgroundColor: "#1C18AF",
    border: "none",
    color: "white",
    padding: "10px 20px",
    cursor: "pointer",
    outline: "none",
    borderRadius: "5px",
    height: "48px",
    margin: "30px 0 0 0",
    fontSize:"16px",
    fontWeight:500,
    textTransform: "none" as const
  },
  BtnBigWhite:{
    backgroundColor: "#1C18AF",
    border: "none",
    borderRadius: "10px",
    color: "white",
    margin: "0 5px",
    height: "54px",
    cursor: "pointer",
    fontSize:"15px",
    textAlign:"start",
    outline: "none",
    width:"230px",
    paddingLeft: "18px",
    textTransform: "none",
  } as const,
  BtnBig:{
    width:"230px",
    height: "54px",
    color: "#808191",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    margin: "0 5px",
    outline: "none",
    textAlign:"start",
    fontSize:"15px",
    paddingLeft: "18px",
    textTransform: "none",
  }as const,
  imageIcon: {
    width: "20px",
    margin: "0 10px 0 10px",
    filter:" brightness(0) invert(1)" as 'brightness(0) invert(1)'
  },
  imageIcon2: {
    width: "18px",
    margin: "0 10px 0 10px",
  },
  addEntryButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding:"0 30px 0 0"
  },
  saveEntryButton: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
    padding:"0 10px 0 0"
  },
  tableContainerr: {
    paddingTop: "40px",
    overflowX: 'inherit',
  },
  arrowIcon: {
    fontSize: '20px',
    cursor: "pointer",
    fontWeight: 600,
    color: "#ccc",
    padding: "8px",
  },
  searchInput: {
    fontSize: "1.1667rem",
    lineHeight: "16px",
   
  },
  searchIconRequest: {
    height: "20px",
    width: "20px",
    margin: "1px 0 0 12px",
  },
  searchContainer: {
    display: "flex",
    minHeight: "48px",
    alignItems: "center",
    height:"48px",
    borderRadius: "8px",
    width:"290px",
    border:"2px solid #1C18AF",
    marginLeft:"10px"
  },
  textsm:{
    fontSize:"16px"
  },
  textlg:{
    fontSize:"22px"
  },
  jobSearchContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    minHeight: "56px",
    width: "100%",
    border: "1.5px solid rgba(0,0,0,0.1)",
  },
  jobInputBase: {
    width: "100%",
    fontSize: "18px",
    color: CONSTANTS.color.grey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "16px",
  },
  jobSearchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  invoiceContainer: {
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
    minHeight: "300px",
    maxWidth: "100%",
    backgroundColor: CONSTANTS.color.white,
    marginTop: "2rem",
    overflow: 'scroll',
},
dividerLineCancelled: {
  marginTop: "8px",
  backgroundColor: "#8C8C8C"
},
dividerLine: {
  marginTop: "8px",
  backgroundColor: CONSTANTS.color.darkBlue
},
dividerColorPending: {
  marginTop: "8px",
  backgroundColor: "#6764d6"
},
dividerColorConverted: {
  marginTop: "8px",
  backgroundColor: "#74ab39"
},
dividerColorQuoted: {
  marginTop: "8px",
  backgroundColor: "#fa6400"
},
dividerColorOverdue: {
  marginTop: "8px",
  backgroundColor: "#a41c1c",
},
dividerColorCancelled: {
  marginTop: "8px",
  backgroundColor: "#8C8C8C"
},
toggleJobModalContainer: {
  position: "absolute" as const,
  width: "254px",
  padding: "1rem",
  minHeight: "194px",
  zIndex: 9,
  backgroundColor: CONSTANTS.color.white,
  borderRadius: "5px",
  boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
  right:0
},
toggleJobModalAr: {
  position: "absolute" as const,
  width: "254px",
  minHeight: "194px",
  zIndex: 9,
  padding: "1rem",
  borderRadius: "5px",
  backgroundColor: CONSTANTS.color.white,
  boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
},
toggleModalStyleJob: {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as const,
},
toggleIconsContainerJob: {
  display: "flex",
  alignItems: "center"
},
toggleIconsJob: {
  width: "24px",
  height: "24px"
},
containeJob: {
  marginTop: "2rem",
  backgroundColor: CONSTANTS.color.white,
  maxWidth: "100%",
  minHeight: "697px",
  boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
},
jobTableHeadSpan: {
  cursor: "pointer",
  borderRadius: "2px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
},
cancelledIcon: {
  width: "30px",
  height: "30px"
},
dropDownSelectIconTable: {
  width: "13px",
  height: "9px",
  cursor: "pointer",
  transform: "rotate(0deg)",
  marginLeft: "2rem",
},
tableText: {
  color: CONSTANTS.color.grey,
  fontWeight: theme.typography.fontWeightRegular,
},
upcomingtxt:{
  fontWeight:"bold",
  color:"#FA8334"
},
backIconContainer: {
  display: "flex",
  alignItems: "center",
  gap: "1rem"
},
dropDownSelectIcon: {
  width: "13px",
  height: "9px",
  cursor: "pointer"
},
jobbutton: {
  backgroundColor: 'green',
},
requestbutton: {
  backgroundColor: 'blue',
},
imgButton: {
  width: "18px",
  height: "18px",
  margin:"0 10px 0 0"
},
imgButtonApprove: {
  height: "18px",
  width: "18px",
  margin:"0 10px 0 0",
  color:"green"
},
gridcont:{
  display: 'flex',
  width: '100%',
  flexWrap: 'nowrap',
  gridAutoFlow: 'column',
},
gridHeigt:{
  maxHeight: '300px',
  overflow: 'scroll',
  minWidth: "fit-content"
},
imgButtonDropdown:{
  width: "14px",
  height: "9px",
  margin:"0 0 0 10px"
},
Centerdata:{
  justifyContent: "center",
  alignItems: "center"
},
transform: {
  width: "13px",
  height: "9px",
  cursor: "pointer",
  transform: "rotate(180deg)"
},
statusModal: {
  display:"flex",
  gridGap:"0.5rem",
  marginBottom:"3px"
},
threeIcon : {
  height:"22px",
  width:"22px"
}

}

export const StyledRoot = styled(Box)({
  "& .statusTextPending": {
    color: "#6764d6",
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 10,
    marginLeft: 10,
    textTransform: "capitalize"
  },
  "& .tableCellBodyDeleteIcon": {
    background: "#eedee4"
  },
  "& .textStart": {
    textAlignLast: "center",
  },
  "& .headLayout": {
    padding: '16px'
  },
  "& .headLayoutMargin": {
    marginTop: '0.5rem'
  },
  "& .tableText": {
    color: "black",
    fontWeight: 500,
  },
  "& .jobCellLayout": {
    padding: '28px 16px 28px 16px !important',
    cursor:"pointer"
  },
  "& .emptyData": {
    color: "grey",
    fontWeight: 500,
  },
  "& .statusKeysText":{color:"blue",fontWeight:500,marginTop:10,marginLeft:10,textTransform:"capitalize"},"& .statusKeysTextPending":{color:"#6764d6",fontWeight:500,marginTop:10,marginLeft:10,textTransform:"capitalize"},"& .statusKeysTextConverted":{color:"#74ab39",fontWeight:500,marginTop:10,marginLeft:10,textTransform:"capitalize"},"& .statusKeysTextQuoted":{color:"#005719",fontWeight:500,marginTop:10,marginLeft:10,textTransform:"capitalize"},"& .statusKeysTextOverdue":{color:"#e02020",fontWeight:500,marginTop:10,marginLeft:10,textTransform:"capitalize"},"& .statusKeysTextCancelled":{color:"#8C8C8C",fontWeight:theme.typography.fontWeightMedium,marginTop:10,marginLeft:10,textTransform:"capitalize"},
  "& .dividerColor": {
    marginTop: "8px",
    backgroundColor: CONSTANTS.color.darkBlue
  },
  "& .createQuoteBtn": {
    marginTop: "3rem"
  },
  "& .menuItemStyle": {
    color: CONSTANTS.color.grey,
    fontWeight: theme.typography.fontWeightRegular,
  
  },
  "& .toggleText":{
    color: CONSTANTS.color.grey,
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace:'initial'
},
  "& .buttonTextCreateQuote": {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center",
  },
  "& .hrs_FontWeight": {
    fontWeight: 500,
    textAlign:"center"
  }
});

const CustomTimeSheet = styled(Dialog)({
  "& .btnCancelPopup":{
    height: "56px",
    width: "156px",
    background: "#ffffff",
    border: "1px solid #999999",
    fontWeight: 500,
    fontSize: "22px",
    borderRadius:"10px",
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    color: "#999999",
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    '&:hover': {
      backgroundColor: '#999999',
      color: "white",
      cursor: "pointer"
    },
    
  },
  "& .btnDeletePopup":{
    '&:hover': {
      backgroundColor: CONSTANTS.color.blueViolet,
      color: "white",
      cursor: "pointer"
    },
    backgroundColor:"#1C18AF",
    color:"#FFFFFF",
    height:"56px",
    transition: 'background-color 0.3s, color 0.3s',
    width:"156px",
    borderRadius:"10px",
    fontSize:"22px"
  },
  "& .MuiPaper-rounded":{
    padding:"15px !important",
    borderRadius:"15px !important",
    "@media (max-width: 420px)": {
      padding:"10px !important",
    },
  },
  "& .MuiButton-label":{
    textTransform:"capitalize",
    fontWeight:"normal",
  },
  "& .MuiDialogActions-root":{
   justifyContent:"space-between"
  },
  "& .MuiTypography-h6":{
    color:"black !important",
    fontSize:"25px !important",
    "@media (max-width: 420px)": {
      fontSize:"20px !important",
      width:"21vh" 
    },
   },
   "& .display":{
    display:"flex",
    justifyContent:"space-between",
   },
   "& .MuiDialogTitle-root":{
    padding:"16px 12px"
   },
   "& .MuiDialogContent-root":{
    padding:"8px 12px"
   },
})
const CustomBtnTimeSheet = styled(Grid)({
  "& .MuiButton-label":{
    justifyContent:"flex-start !important",
    "@media (max-width: 600px)": {
      display:"initial"
    }
  },
  "& .timesheetBtncontainer" : {
    "@media (max-width: 600px)": {
      height:"110px !important",
      width:"112px !important",
      alignItems:"start"
    }
  },
  "& .EmptimesheetBtncontainer" : {
    "@media (max-width: 600px)": {
      height:"110px !important",
      width:"112px !important"
    }
  },
  "& .imgAlign":{
    "@media (max-width: 600px)": {
      margin:"16px 0 0 26px !important"
    }
  },
  "& .imgEmpAlign":{
    "@media (max-width: 600px)": {
      margin:"0 0 0 26px !important"
    }
  }
})
const CustomFormWeekly = styled(StyledRoot)({
  "& .addInputText": {
    color:"gray",
    fontSize:"16px"
  },
 
  "& .MuiContainer-root": {
    paddingLeft:"0px"
  },
  "& .boxResponse": {
    marginTop: "8.5rem",
    marginLeft:"320px",
    "@media (max-width: 1279px)": {
      marginLeft:"15px",
      marginTop: "8rem",
    },
  },
  "& .boxMainWeekly": {
    marginTop: "8.5rem",
    marginRight:"320px",
    width: "100%",
    margin: "20px",
    overflowX: "scroll" as "scroll",
    "@media (max-width: 1279px)": {
      marginTop: "8rem",
      marginRight:"15px",
    },
  },
  "& .btnApproved":{
    width :"168px",
    height:"44px",
    border:"1px solid #1C18AF",
    color:"#1C18AF",
    backgroundColor:"#E9E9FF",
    textTransform: "none" as const,
    borderRadius:"25px",
  },
  "& .btnPending":{
    width:"168px",
    color:"#FA8334",
    backgroundColor:"#FFF5EF",
    border:"1px solid #FA8334",
    textTransform: "none" as const,
    height:"44px",
    borderRadius:"25px",
  },
  "& .btnRejected":{
    width:"168px",
    height:"44px",
    color:"#FA3434",
    backgroundColor:"#FFEFEF",
    borderRadius:"25px",
    border:"1px solid #FA3434",
    textTransform: "none" as const
  },
  "& .btnApproveAll":{
    width:"168px",
    textTransform: "none" as const,
    height:"44px",
    borderRadius:"25px",
    border:"1px solid #50AF18",
    color:"#50AF18 !important",
    backgroundColor:"#F0FFE7",
   
  },
  "& .gridrespo":{
    "@media (max-width: 1290px)":{
      width:"140ch"
    },
    flexWrap:"nowrap",
  },
  "& .textStart":{
    textAlignLast:"center"
  },
  "& .buttonAdd": {
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: "white",
        cursor: "pointer"
    },
    backgroundColor: "#1C18AF",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "0 5px",
    outline: "none",
    height: "48px",
    fontSize:"14px",
    fontWeight:500,
    textTransform: "none" as const,
    width:"100%",
    maxWidth:"150px"
  },
  "& .buttonWeekly":{
    backgroundColor: "#FFFFFF",
    transition: 'background-color 0.3s, color 0.3s',
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    color: "#808191",
    padding: "10px 24px",
    outline: "none",
    height:"48px",
    textTransform: "none" as const,
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: "white",
        cursor: "pointer"
    },
  },
  "& .MuiTableContainer-root":{
    overflowX:"hidden",
    "@media (max-width: 800px)":{
      width:"100vh"
    }
  },
  "& .CustomDatePicker-inputBaseStyle-60": {
     width:"168px",
    border:"none !important",
    "@media (max-width: 768px)": {
      border:"none !important",
    },
  },
  "& .MuiInputBase-root":{
    border:"none !important",
  },
  "& .MuiPaper-elevation1": {
    boxShadow:"none",
  },
  "& .widthfull": {  
    
    "@media (max-width: 768px)": {
      width:"220px"
    },
  },
  "& .widthfullName": {  
    width:"236px",
    "@media (max-width: 768px)": {
      width:"220px"
    },
  },
  "& .MuiGrid-spacing-xs-3": {
    width:"fitContent",
  },
  "& .Widthtop": {
    padding:"5px 0",
    margin:"30px 0",
  },
  "& .boarderBott": {
    borderBottom:"1px solid rgba(0, 0, 0, 0.12)",
    margin:"15px 0 25px 0"
  },
  "& .padingresponsive": {
    fontSize:"24px",
    color: "rgb(41, 46, 52)",
    fontWeight: 500,
    marginTop:"5px",
    "@media (max-width: 1279px)": {
      paddingInline:"20px",
      
    },
  },
  "& .button": {
    backgroundColor: "#1C18AF",
    color: "white",
    padding: "10px 24px",
    outline: "none",
    height: "48px",
    textTransform: "none" as const,
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: "white",
        cursor: "pointer"
    },
  },
  "& .textSize":{
    fontSize:"20px",
    marginTop:"10px",
    width:"290px",
    height:"67px",
    borderRadius: "15px",
    backgroundColor: "#1C18AF",
    color: "white",
    padding: "10px 10px",
    border: "none",
    textTransform: "none" as const,
    fontWeight:"normal",
    "@media (max-width: 420px)": {
      width:"320px"
    },
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
        backgroundColor: CONSTANTS.color.blueViolet,
        color: "white",
        cursor: "pointer"
    },
  },
  "& .MuiAutocomplete-input":{
    width:"0%",
    margin :"0 12px 0 12px"
  },
  "& .MuiGrid-grid-sm-3": {
    maxWidth: "fit-content",
    padding:"0 15px 0 0"
  }

})
const CustomMenu = styled(Menu)({
  "& .main-title-menu":{
    padding: "10px 16px",
    color: 'black',
    fontSize: 16,
  },
  "& .radeous": {
    borderRadius:"20px"
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: '#1C18AF !important',
    color: '#FFFFFF !important',
  },
  "& .MuiList-root":{
    padding:0
  }
})
const CustomMenu2 = styled(Menu)({
  "& .main-title-menu":{
    padding: "10px 16px",
    color: 'black',
    fontSize: 16
  },
  "& .MuiList-root":{
    padding:0
  },
  "& .MuiListItem-button":{
    padding: 12,
    borderRadius: 10,
  },
  "& .MuiListItem-button:hover": {
    backgroundColor:"#E9E9FF !important",
  },
  "& .colRejected": {
    color:"#FA3434",
  },
  "& .colpending": {
    color:"#FA8334",
  },
  "& .colapprove": {
    color:"#1C18AF",
  }
 
})
const CustomInput = withStyles({
  root: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
})(Input);
const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    height:"inherit",
    width:"800px",
    maxWidth:"inherit",
  },
  "& .buttonstyle": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom:"1px solid black",
    paddingBottom:"25px"
  },
  "& .dflex":{
    display:"flex",
   },
   "& .selectedBtn":{
      backgroundColor: "#1C18AF",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      outline: "none",
      textTransform: "none" as const
   },
   "& .unSelectBtn":{
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    outline: "none",
    textTransform: "none" as const
 },
 "& .MuiGrid-item":{
    marginTop:"25px"
  }
})

const StyledBox = styled(Grid)({
  marginTop: "2rem",
  maxWidth: "100%",
  backgroundColor: CONSTANTS.color.white,
  boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
  minHeight: "300px",
  overflow: 'scroll',
  '&::-webkit-scrollbar-thumb': {
      backgroundColor:"#1c18af"
  }
});

const StyledBox2 = styled(Grid)({
  "& .btnSaveDrid": {
    width:"140ch !important"
  },
  "& .btnSave": {
    width:"150px",
    "@media (max-width: 420px)": {
      width:"380px"
    },
  }
});

const StyledMenuItem = withStyles(({
  root: {
    borderRadius:10,
    '& .MuiListItemIcon-root': {
      color: "rgb(28, 24, 175)",
      minWidth: 35
    },
    '&:hover': {
      backgroundColor: "rgb(28, 24, 175)",
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: "white",
      },
    },
  },
}))(MenuItem);


const StyledDatePickerWrapper = styled(Grid)({
  "& .spacingSmall" : {
    border: "none",      
    boxShadow: "none"  ,
    marginTop: "21rem",
    
  },
  "& .MuiBox-root": {
     width:"189px",
    border:"none !important",
    "@media (max-width: 768px)": {
      border:"none !important",
    },
  }, 

});





const TimesheetSnackbar = withSnackbar(TimeSheetWeekly)
export default withTranslation()(TimesheetSnackbar);
// Customizable Area End