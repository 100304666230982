import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { TaxRates, MockBusiness } from "./CompanyInformation.web";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { checkLoggedInUser } from "../../CustomisableUserProfiles/src/utility.web";
export const configJSON = require("./config");

// Customizable Area Start
import i18n from 'i18next'; 
import { checkLoggedInUserSettings } from "./utility.web";
const data = [
  {
    id: 1,
    title: "Monday",
    closed: false,
    opensAt: configJSON.mockWeeklyLabel.time,
    closedAt: configJSON.mockWeeklyLabel.time,
  },
  {
    id: 2,
    title: "Tuesday",
    closed: false,
    opensAt: configJSON.mockWeeklyLabel.time,
    closedAt: configJSON.mockWeeklyLabel.time,
  },
  {
    id: 3,
    title: "Wednesday",
    closed: false,
    opensAt: configJSON.mockWeeklyLabel.time,
    closedAt: configJSON.mockWeeklyLabel.time,
  },
  {
    id: 4,
    title: "Thursday",
    closed: false,
    opensAt: configJSON.mockWeeklyLabel.time,
    closedAt: configJSON.mockWeeklyLabel.time,
  },
  {
    id: 5,
    title: "Friday",
    closed: false,
    opensAt: configJSON.mockWeeklyLabel.time,
    closedAt: configJSON.mockWeeklyLabel.time,
  },
  {
    id: 6,
    title: "Saturday",
    closed: false,
    opensAt: configJSON.mockWeeklyLabel.time,
    closedAt: configJSON.mockWeeklyLabel.time,
  },
  {
    id: 7,
    title: "Sunday",
    closed: false,
    opensAt: configJSON.mockWeeklyLabel.time,
    closedAt: configJSON.mockWeeklyLabel.time,
  },
]

type Business = {
  id: number;
  title: string;
  closed: boolean;
  opensAt: string;
  closedAt: string;
}[]

type ErrorFormData = {
  companyName?: string;
  countryCode?: string;
  phone?: string;
  email?: string;
  websiteUrl?: string;
  country?: string;
  taxIdName?: string;
  taxIdNumber?: string;
};

type ErrorTaxFormData = {
  name?: string;
  rate?: string;
  description?: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  active: number;
  progress: number;
  location: any;
  history: any;
  openToastHandler: Function;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  countryCode: string;
  mockBuisinessHours: Business;
  hexColor: string;
  companyDetails: {
    id: string;
    companyName: string;
    phone: string;
    email: string;
    websiteUrl: string;
  };
  regionalSettings: {
    country: string;
    currency: string;
    timezone: string;
    dateFormat: string;
    timeFormat: string;
    firstDayOfTheWeek: string;
  };
  companyLogoEdit: any;
  companyLogoData: any;
  deletedCompanyLogoId: any;
  companyLogoEditLocal: any;
  logoChangeModal: boolean;
  isHovered: boolean;
  isHovered2: boolean;
  manageTaxDialogOpen: boolean;
  taxSettings: {
    taxIdName: string;
    taxIdNumber: string;
  };
  manageTaxRates: {
    name: string;
    rate: string;
    description: string;
  };
  manageTaxRatesData: any;
  progress: number;
  logoCompleted: boolean;
  getCompanyInformationLoading: boolean;
  updateCompanyInformationLoading: boolean;
  countriesLoading: boolean;
  countriesData: any;
  isErrorFormData: ErrorFormData;
  isErrorTaxFormData: any;
  isEmployee:boolean;
  hideSideBar: boolean;
  languageSelected: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyDetailsApiCallId: string = "";
  updateCompanyDetailsApiCallId: string = "";
  getCountriesListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      countryCode: "",
      mockBuisinessHours: data,
      hexColor: "",
      companyDetails: {
        id: "",
        companyName: "",
        phone: "",
        email: "",
        websiteUrl: "",
      },
      regionalSettings: {
        country: configJSON.selectCountryLabel,
        currency: configJSON.selectCurrencyLabel,
        timezone: configJSON.selectTimezoneLabel,
        dateFormat: configJSON.selectDateFormatLabel,
        timeFormat: configJSON.selectTimeFormatLabel,
        firstDayOfTheWeek: configJSON.selectFirstDayOfTheWeekLabel,
      },
      companyLogoEdit: null,
      companyLogoData: {},
      deletedCompanyLogoId: null,
      companyLogoEditLocal: null,
      logoChangeModal: false,
      isHovered: false,
      isHovered2: false,
      manageTaxDialogOpen: false,
      taxSettings: {
        taxIdName: "",
        taxIdNumber: "",
      },
      manageTaxRates: {
        name: "",
        rate: "",
        description: ""
      },
      manageTaxRatesData: [],
      progress: 0,
      logoCompleted: false,
      getCompanyInformationLoading: false,
      updateCompanyInformationLoading: false,
      countriesLoading: false,
      countriesData: [],
      isErrorFormData: {},
      isErrorTaxFormData: {},
      isEmployee:false,
      hideSideBar: true,
      languageSelected: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    if(this.props.active!==1){
      removeStorageData("cancelRedirection")
    }
    window.scrollTo(0, 0);
    if (!await getStorageData("authToken")) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }

    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });

    const user =await checkLoggedInUserSettings()
    this.setState({isEmployee:user})

    this.getCompanyInformation();
    this.setState({ getCompanyInformationLoading: true });
    
    this.getCountries();
    this.setState({ countriesLoading: true });

    this.handleSideBarHide();
    // Customizable Area End
  }

  // Customizable Area Start
  tokenExpired = (isEmployee: boolean) => {
    setTimeout(this.setTimeOutHandler.bind(this, isEmployee), 2000);
  }

  setTimeOutHandler = (isEmployee: boolean) => {
    localStorage.clear();
    this.props.navigation.history?.push(isEmployee ? "/Employee/Login" : "/Login");
  }

  errorHandlerMessage = async (error: any) => {
    const { token } = error;
    if (token === configJSON.tokenExpired || token === configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }

  errorResponseMessage = (error: string) => {
    if (error) {
      this.errorHandlerMessage(error);
    }
  }
  
  extractDataFromMessage = (message: Message) => {
    return {
      apiRequestCallId: message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ),
      responseJson: message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ),
      errorResponse: message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      ),
    };
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getCompanyDetailsApiCallId) {
        if (!responseJson.errors) {
          this.setState({ getCompanyInformationLoading: false });
          this.handleGetCompanyInformationData(responseJson);
        } else if (responseJson.errors) {
          this.setState({ getCompanyInformationLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ getCompanyInformationLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    this.handleUpdateCompanyInformation(message);
    this.handleGetCountries(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetCompanyInformationData = async (responseJson: any) => {
    const companyDetailsEdit = {
      id: responseJson.data?.id,
      companyName: responseJson.data?.attributes?.company_name,
      phone: responseJson.data?.attributes?.phone_number?.split(" ")[1],
      email: responseJson.data?.attributes?.email_address,
      websiteUrl: responseJson.data?.attributes?.website_url || ""
    }
    const countryCodeEdit = responseJson?.data?.attributes?.phone_number?.split(" ")[0];
    const firstDayOfTheWeek = responseJson.data?.attributes?.first_day_of_week;
    const timeFormat = responseJson.data?.attributes?.time_format;
    const dateFormat = responseJson.data?.attributes?.date_format;

    const regionalSettingsEdit = {
      country: responseJson.data?.attributes?.country || configJSON.selectCountryLabel,
      currency: responseJson.data?.attributes?.currency || configJSON.selectCurrencyLabel,
      timezone: responseJson.data?.attributes?.timezone || configJSON.selectTimezoneLabel,
      dateFormat: this.state.languageSelected === "ar" ? this.handleDateFormatArCallback(dateFormat) : responseJson.data?.attributes?.date_format || configJSON.selectDateFormatLabel,
      timeFormat: this.state.languageSelected === "ar" ? this.handleTimeFormatArCallback(timeFormat) : responseJson.data?.attributes?.time_format || configJSON.selectTimeFormatLabel,
      firstDayOfTheWeek: this.state.languageSelected === "ar" ? this.handleFirstDayOfWeekAr(firstDayOfTheWeek) : responseJson.data?.attributes?.first_day_of_week || configJSON.selectFirstDayOfTheWeekLabel
    };
    await setStorageData("timeFormat", responseJson.data?.attributes?.time_format);
    const buisinessHours: {
      [key: string]: { start_time: string; end_time: string; closed: any }
    } = responseJson.data?.attributes?.business_hours ||[]
    if(buisinessHours !== null) {
      const mockBuisinessHoursEdit = buisinessHours&&Object?.entries(buisinessHours)?.map(([day, data], index) => {
        const translatedDay = i18n.t(`settings.${day.toLowerCase()}`);
        return {
          id: index + 1,
          title: translatedDay,
          closed: data?.closed === "true",
          opensAt: data?.start_time,
          closedAt: data?.end_time
        }
      });
      this.setState({ mockBuisinessHours: mockBuisinessHoursEdit });
    }
    
    const companyLogoEdit = responseJson.data?.attributes?.company_logo?.url
    const taxSettingsEdit = {
      taxIdName: responseJson.data?.attributes?.tax_id_name || "",
      taxIdNumber: responseJson.data?.attributes?.tax_id_number || ""
    }
    const taxRates = responseJson.data?.attributes?.tax_rates
    this.setState({
      companyLogoEdit: companyLogoEdit,
      hexColor: responseJson.data?.attributes?.pdf_hex_colour,
      companyDetails: companyDetailsEdit,
      countryCode: countryCodeEdit,
      regionalSettings: regionalSettingsEdit,
      taxSettings: taxSettingsEdit,
      manageTaxRatesData: taxRates,
      companyLogoData: responseJson.data?.attributes?.company_logo
    });
  }

  handleTimeFormatArCallback = (timeFormat: string) => {
    const formatMap: Record<string, string> = {
      "12-Hour Clock": "تنسيق ١٢ ساعه",
      "24-Hour Clock": "تنسيق ٢٤ ساعه"
    };
    return formatMap[timeFormat] || timeFormat;
  };

  handleFirstDayOfWeekAr = (firstDayOfTheWeek: string) => {
    const dayMap: Record<string, string> = {
      "Monday": "الاثنين",
      "Sunday": "الأحد"
    };
    return dayMap[firstDayOfTheWeek] || firstDayOfTheWeek;
  };

  handleDateFormatArCallback = (dateFormat: string) => {
    const dateFormatMap: Record<string, string> = {
      "MM/DD/YYYY": "شهر/يوم/سنة",
      "DD/MM/YYYY": "يوم/شهر/سنة",
      "YYYY-MM-DD": "سنة-شهر-يوم",
      "DD-MMM-YYYY Or DD MMM YYYY": "يوم-شهر-سنة أو يوم شهر سنة",
      "Month DD, YYYY": "الشهر يوم، سنة",
      "Day, DD MMM YYYY": "اليوم، يوم شهر سنة",
      "DDth Of Month YYYY": "يوم من الشهر سنة",
      "YYYY-DDD": "سنة-يوم"
    };
    return dateFormatMap[dateFormat] || dateFormat;
  };
  
  handleTimeFormat = (timeFormat: string) => {
    const formatMap: Record<string, string> = {
      "تنسيق ١٢ ساعه": "12-Hour Clock",
      "تنسيق ٢٤ ساعه": "24-Hour Clock"
    };
    return formatMap[timeFormat] || timeFormat;
  };

  handleFirstDayOfTheWeekAr = (firstDayOfTheWeek: string) => {
    const dayMap: Record<string, string> = {
      "الاثنين": "Monday",
      "الأحد": "Sunday"
    };
    return dayMap[firstDayOfTheWeek] || firstDayOfTheWeek;
  };

  handleDateFormat = (dateFormat: string) => {
    const dateFormatMap: Record<string, string> = {
      "شهر/يوم/سنة": "MM/DD/YYYY",
      "يوم/شهر/سنة": "DD/MM/YYYY",
      "سنة-شهر-يوم": "YYYY-MM-DD",
      "يوم-شهر-سنة أو يوم شهر سنة": "DD-MMM-YYYY Or DD MMM YYYY",
      "الشهر يوم، سنة": "Month DD, YYYY",
      "اليوم، يوم شهر سنة": "Day, DD MMM YYYY",
      "يوم من الشهر سنة": "DDth Of Month YYYY",
      "سنة-يوم": "YYYY-DDD"
    };
    return dateFormatMap[dateFormat] || dateFormat;
  }
  
  handleUpdateCompanyInformation = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.updateCompanyDetailsApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            updateCompanyInformationLoading: false,
            deletedCompanyLogoId: null
          });
          this.getCompanyInformation();
          localStorage.setItem('dateFormate',this.state.regionalSettings.dateFormat)
        } else if (responseJson.errors) {
          this.setState({ updateCompanyInformationLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ updateCompanyInformationLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleGetCountries= (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getCountriesListApiCallId) {
        if (!responseJson.errors) {
          this.setState({ countriesLoading: false });
          this.setState({ countriesData: responseJson });
        } else {
          //Check Error Response
          this.setState({ countriesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleSelectFiles = (event: any) => {
    const file = event.target?.files?.[0];
    if(file) {
      this.setState({
        companyLogoEditLocal: file,
        logoChangeModal: false,
        isHovered: false,
        logoCompleted: true,
        progress: 0,
      });
      this.handleProgressEvent(file);
    }
  }

  handleProgressEvent = (file: any) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadstart = () => {
        this.setState({ progress: 0 });
      };
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          this.setState({ progress: percentComplete });
        }
      };
      reader.onload = (event: any) => {
        this.setState({ progress: 100 }, () => {
          setTimeout(() => {
            this.setState({ logoCompleted: false });
          }, 3000);
        });
      };
      reader.onerror = (event: any) => {
        console.error("File could not be read! Code " + event.target.error.code);
      };
      const blob = new Blob([file], { type: file.type });
      reader.readAsDataURL(blob);
    }
  }

  handleOnDragOver = (event: any) => {
    event.preventDefault();
  }

  handleOnDrop = (event: any) => {
    event.preventDefault();
  
    const file = event?.dataTransfer?.files?.[0];
    if (file) {
      this.setState({ companyLogoEditLocal: file });
      this.handleProgressEvent(file);
    }
  }
  
  handleCloseManageTaxDialog = () => {
    this.setState({ manageTaxDialogOpen: false });
  }
  
  handleManageTaxRates = () => {
    this.setState({ manageTaxDialogOpen: true });
  }
  
  handleDeleteUploadedLogo = () => {
    this.setState({
      deletedCompanyLogoId: this.state.companyLogoData?.id,
      companyLogoEditLocal: null,
      companyLogoEdit: null,
      logoChangeModal: false,
      isHovered2: false
    });
  }
  
  handleClickEditIcon = () => {
    this.setState({ logoChangeModal: !this.state.logoChangeModal });
  }
  
  handleChangeCompanyDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ companyDetails: { ...this.state.companyDetails, [e?.target.name]: e?.target.value } });
  }

  handleChangeHexColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ hexColor: e?.target.value });
  }
  
  handleChangeTaxSettings = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ taxSettings: { ...this.state.taxSettings, [e.target.name]: e.target.value } });
  }
  
  handleChangeTaxRates = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ manageTaxRates: { ...this.state.manageTaxRates, [e?.target.name]: e?.target.value } });
  }

  handleChangeTaxRate = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedTaxRatesData = [...this.state.manageTaxRatesData];
    updatedTaxRatesData[index][e?.target.name] = e?.target.value;
    this.setState({ manageTaxRatesData: updatedTaxRatesData });

    const validationErrors = this.validateTaxRatesData(updatedTaxRatesData[index]);
    
    this.setState({
      manageTaxRatesData: updatedTaxRatesData,
      isErrorTaxFormData: { ...this.state.isErrorTaxFormData, [index]: validationErrors }
    });
  };

  validateTaxRatesData = (values: ErrorTaxFormData) => {
    const errors: ErrorTaxFormData = {};
    const regexName = this.state.languageSelected === "ar" ?
    /^\s*[a-zA-Z\u0621-\u064A]+(?:\s[a-zA-Z\u0621-\u064A]+)*\s*$/ :
    /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
    const regexDecimals = /^\s*\d+(\.\d+)?\s*$/;
    
    if (values.name && !regexName.test(values.name)) {
      errors.name = configJSON.companyInformationErrorText.alphabeticErrorMessage
    } else if (values.name && values.name.length > 23) {
      errors.name = configJSON.companyInformationErrorText.nameLengthErrorMessage;
    }

    if (values.rate && !regexDecimals.test(values.rate)) {
      errors.rate = configJSON.companyInformationErrorText.numericalErrorMessage
    }
    
    if (values.description && !regexName.test(values.description)) {
      errors.description = configJSON.companyInformationErrorText.alphabeticErrorMessage
    }

    return errors;
  }

  handleSaveTaxRates = () => {
    this.setState({ isErrorTaxFormData: this.validateTaxRatesData(this.state.manageTaxRates) },
      () => {
        if(Object.keys(this.state.isErrorTaxFormData).length === 0) {
          if(this.state.manageTaxRates.name || this.state.manageTaxRates.rate || this.state.manageTaxRates.description) {
            this.setState({
              manageTaxRatesData: [
                ...this.state.manageTaxRatesData, this.state.manageTaxRates
              ],
              manageTaxDialogOpen: false,
              manageTaxRates: {
                ...this.state.manageTaxRates, name: "", rate: "", description: ""
              }
            });
          }
        }
      }
    );
  }

  handleDeleteTaxRate = (index: number) => {
    const updatedTaxRates = [...this.state.manageTaxRatesData]
    updatedTaxRates.splice(index, 1);
    this.setState({ manageTaxRatesData: updatedTaxRates });
  }

  handleChangeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCountryObject = this.state.countriesData?.find((item: any) => item[e?.target.name] === e?.target.value);
    this.setState({
      regionalSettings: {
        ...this.state.regionalSettings,
        country: selectedCountryObject?.country,
        currency: selectedCountryObject?.currency,
        timezone: selectedCountryObject?.timezone
      }
    });
  }
  
  handleChangeRegionalSettings = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      regionalSettings: {
        ...this.state.regionalSettings, [e?.target.name]: e?.target.value
      }
    });
    if(e?.target.name === "timeFormat") {
      if(e?.target.value === "24-Hour Clock" || "12-Hour Clock") {
        this.setState({ mockBuisinessHours: data });
      }
    }
  }
  
  handleCheckClosed = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const updatedCheckClosed = this.state.mockBuisinessHours.map((item: MockBusiness) => {
      if(item.id === id) {
        return { ...item, closed: !item.closed };
      } else {
        return item;
      }
    });
    this.setState({ mockBuisinessHours: updatedCheckClosed });
  }

  handleChangeHours = (id: number, key: string, timeValue: string) => {
    const updatedSchedule = this.state.mockBuisinessHours.map((item: MockBusiness) => {
      if (item.id === id) {
        return { ...item, [key]: timeValue };
      } else {
        return item;
      }
    });
    this.setState({ mockBuisinessHours: updatedSchedule });
  }
  
  handleTabValueChange = async (event: React.ChangeEvent<HTMLInputElement>, value: number) => {
    const isEmployee = this.state.isEmployee;
    this.handleTabs(isEmployee,value)
  }

  handleNavigate = (value: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), value);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleTabs = (isEmployee:boolean,value:number)=>{
    if(isEmployee) {
      if(value === 0) {
        this.handleNavigate(configJSON.CompanyInformation);
      } else if(value === 1) {
        this.handleNavigate(configJSON.ProductAndServices);
      } else if(value === 2) {
        this.handleNavigate(configJSON.Notification);
      }
    } else {
      if(value === 0) {
        this.handleNavigate(configJSON.CompanyInformation);
      } else if(value === 1) {
        this.handleNavigate(configJSON.ProductAndServices);
      } else if(value === 2) {
        this.handleNavigate(configJSON.Billing);
      } else if(value === 3) {
        this.handleNavigate(configJSON.Notification);
      }
    }
  }

  handleChangeCountryCode = (event: React.ChangeEvent<{}>, value: any) => {
    this.setState({ countryCode: value });
  };

  validateFormData = (values: ErrorFormData) => {
    const errors: ErrorFormData = {};
    const regexName = this.state.languageSelected === "ar" ?
    /^\s*[a-zA-Z\u0621-\u064A]+(?:\s[a-zA-Z\u0621-\u064A]+)*\s*$/ :
    /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
    const regexWebsite = /^\s*[a-zA-Z.]+(?:\s[a-zA-Z.]+)*\s*$/
    const regexEmail = /^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;

    const { t } = this.props;
    if (!values.companyName) {
      errors.companyName = t('settings.companyNameRequired');
    } else if (values.companyName && !regexName.test(values.companyName)) {
      errors.companyName = t('settings.companyNameAlphabetic');
    } else if (values.companyName && values.companyName.length > 40) {
      errors.companyName = t('settings.companyNameCharacters');
    }

    this.validateCountryPhone(values, errors);
    
    if (!values.email) {
      errors.email = t('settings.emailRequired');
    } else if (values.email && !regexEmail.test(values.email)) {
      errors.email = t('settings.emailInvalid');
    }

    if (values.websiteUrl && !regexWebsite.test(values.websiteUrl)) {
      errors.websiteUrl = t('settings.enterValidWebsite');
    }

    if (!this.state.regionalSettings.country) {
      errors.country = t('settings.countryRequired');
    }

    this.validateTaxSettings(values, errors);

    return errors;
  }

  validateCountryPhone = (values: ErrorFormData, errors: ErrorFormData) => {
    const regexPhone = /^\s*\d+\s*$/;
    const { t } = this.props;

    if (!this.state.countryCode) {
      errors.countryCode = t('settings.countryCodeRequired');
    } 

    if (!values.phone) {
      errors.phone = t('settings.phoneNumberRequired');
    } else if (values.phone && !regexPhone.test(values.phone)) {
      errors.phone = t('settings.numericalError');
    }
  }

  validateTaxSettings = (values: ErrorFormData, errors: ErrorFormData) => {
    const regexTaxIdNumber = /^\s*[a-zA-Z0-9]+\s*$/;
    const { t } = this.props;

    if (values.taxIdName && !regexTaxIdNumber.test(values.taxIdName)) {
      errors.taxIdName = t('settings.alphaNumerical');
    } else if (values.taxIdName && values.taxIdName.length > 23) {
      errors.taxIdName = t('settings.taxIdNameError');
    }

    if (values.taxIdNumber && !regexTaxIdNumber.test(values.taxIdNumber)) {
      errors.taxIdNumber = t('settings.alphaNumerical');
    }
  }

  handleUpdateSettings = () => {
    this.setState({ isErrorFormData: this.validateFormData({...this.state.companyDetails, ...this.state.taxSettings}) },
      () => {
        if(Object.keys(this.state.isErrorFormData).length === 0 && Object.keys(this.state.isErrorTaxFormData).every(key => Object.keys(this.state.isErrorTaxFormData[key]).length === 0)) {
          this.updateCompanyInformation();
          this.setState({ updateCompanyInformationLoading: true });
        }
      }
    );
  }

  handleMouseEnterChangeLogo = () => {
    this.setState({ isHovered: true });
  }

  handleMouseLeaveChangeLogo = () => {
    this.setState({ isHovered: false });
  }

  handleMouseEnterDeleteLogo = () => {
    this.setState({ isHovered2: true });
  }

  handleMouseLeaveDeleteLogo = () => {
    this.setState({ isHovered2: false });
  }

  handleSideBarHide = () => {
    if(window.innerWidth < 960) {
      this.setState({ hideSideBar: false });
    } else {
      this.setState({ hideSideBar: true });
    }
  }

  handleGoBackIcon = () => {
    this.props.navigation.history.goBack();
  }

  getCompanyInformation = async () => {
    const header = {
      "Content-Type": configJSON.settingsContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompanyDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyInformationAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCompanyInformationAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  translateToEnglish = (arabicTitle: string) => {
    if (arabicTitle ===  "الاثنين") {
      return "Monday";
    } else if (arabicTitle === "يوم الثلاثاء") {
      return "Tuesday";
    } else if (arabicTitle === "الأربعاء") {
      return "Wednesday";
    } else if (arabicTitle === "يوم الخميس") {
      return "Thursday";
    } else if (arabicTitle === "جمعة") {
      return "Friday";
    } else if (arabicTitle === "السبت") {
      return "Saturday";
    } else if (arabicTitle === "الأحد") {
      return "Sunday";
    } else {
      return arabicTitle;
    }
  };

  translateToEnglishTime = (arabicTime: string) => {
    const [hour, minute, period] = arabicTime?.split(/[ :]/);
    let translatedHour = String(hour);
    let translatedMinute = String(minute);
    
    const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
    const englishNumerals = "0123456789";
    
    for (let i = 0; i < arabicNumerals.length; i++) {
      translatedHour = translatedHour.replace(new RegExp(arabicNumerals[i], 'g'), englishNumerals[i]);
      translatedMinute = translatedMinute.replace(new RegExp(arabicNumerals[i], 'g'), englishNumerals[i]);
    }

    let translatedPeriod = period;
    if (period === "مساء") {
      translatedPeriod = "PM";
    } else if (period === "صباخا") {
      translatedPeriod = "AM";
    }

    if (period === "مساء" && parseInt(translatedHour, 10) < 12) {
      translatedHour = String(parseInt(translatedHour, 10) + 12);
    }

    translatedHour = translatedHour.padStart(2, '0');
    translatedMinute = translatedMinute.padStart(2, '0');

    return `${translatedHour}:${translatedMinute} ${translatedPeriod || ""}`;
  };

  handleMockBusinessData = (formdata: FormData, item: {
    id: number;
    title: string;
    closed: boolean;
    opensAt: string;
    closedAt: string;
  }) => {
    const { opensAt, closedAt } = item;
    const translatedOpensAt = this.translateToEnglishTime(opensAt);
    const translatedClosedAt = this.translateToEnglishTime(closedAt);
    const translatedTitle = this.translateToEnglish(item.title);
    formdata.append(`data[business_hours][${translatedTitle}][start_time]`, translatedOpensAt);
    formdata.append(`data[business_hours][${translatedTitle}][end_time]`, translatedClosedAt);
    formdata.append(`data[business_hours][${translatedTitle}][closed]`, item.closed ? "true" : "false");
  }

  getSelectedValue = (value: string, selectedValue: string, handler: Function, configLabel: string) => {
    if (this.state.languageSelected === "ar") {
      return handler(value);
    } else if (value !== configLabel) {
      return value;
    }
    return selectedValue;
  };

  updateCompanyInformation = async () => {
    const { companyLogoEditLocal } = this.state;
    const { id, companyName, phone, email, websiteUrl } = this.state.companyDetails;
    const { countryCode, hexColor } = this.state;
    const {
      country,
      currency,
      timezone,
      dateFormat,
      timeFormat,
      firstDayOfTheWeek
    } = this.state.regionalSettings;
    const { taxIdName, taxIdNumber } = this.state.taxSettings;
    const { mockBuisinessHours, companyLogoEdit, deletedCompanyLogoId } = this.state;
    const { manageTaxRatesData } = this.state;
    const header = {
      "token": await getStorageData(configJSON.authToken),
    };

    const selectedDateFormat = this.getSelectedValue(dateFormat, "", this.handleDateFormat, configJSON.selectDateFormatLabel);
    const selectedTimeFormat = this.getSelectedValue(timeFormat, "", this.handleTimeFormat, configJSON.selectTimeFormatLabel);
    const selectedFirstDayOfWeek = this.getSelectedValue(firstDayOfTheWeek, "", this.handleFirstDayOfTheWeekAr, configJSON.selectFirstDayOfTheWeekLabel);

    let formdata = new FormData();
    if (companyLogoEditLocal !== null) {
      formdata.append("data[company_logo]", companyLogoEditLocal || "");
    }
    formdata.append("data[pdf_hex_colour]", hexColor);
    formdata.append("data[company_name]", companyName?.trim());
    formdata.append("data[phone_number]", `${countryCode || ""} ${phone?.trim() || ""}`);
    formdata.append("data[website_url]", websiteUrl?.trim());
    formdata.append("data[email_address]", email?.trim());
    formdata.append("data[country]", country === configJSON.selectCountryLabel ? "" : country);
    formdata.append("data[currency]", currency === configJSON.selectCurrencyLabel ? "" : currency);
    formdata.append("data[timezone]", timezone === configJSON.selectTimezoneLabel ? "" : timezone);
    formdata.append("data[date_format]", selectedDateFormat);
    formdata.append("data[time_format]", selectedTimeFormat);
    formdata.append("data[first_day_of_week]", selectedFirstDayOfWeek);
    formdata.append("data[tax_id_name]", taxIdName?.trim());
    formdata.append("data[tax_id_number]", taxIdNumber?.trim());
    
    mockBuisinessHours?.forEach(this.handleMockBusinessData.bind(this, formdata));

    manageTaxRatesData?.forEach((item: TaxRates, index: number) => {
      const { name, rate, description } = item;
      formdata.append(`data[tax_rates_attributes][${index}][name]`, name?.trim());
      formdata.append(`data[tax_rates_attributes][${index}][rate]`, String(rate)?.trim());
      formdata.append(`data[tax_rates_attributes][${index}][description]`, description?.trim());
    });

    if(companyLogoEdit === null && deletedCompanyLogoId) {
      formdata.append("image_id", deletedCompanyLogoId);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCompanyDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyInformationAPIEndPoint}/${id}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateCompanyInformationAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getCountries = async () => {
    const header = {
      "Content-Type": configJSON.settingsContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountriesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.countriesAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCountiresAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
// Customizable Area End