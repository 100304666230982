export const editIcon=require("../assets/edit.png")
export const uploadIcon=require("../assets/upload.png")
export const rightArrow=require("../assets/arrow.png")
export const leftArrow=require("../assets/arrowleft.png")
export const location=require("../assets/location.png")
export const convertToJob=require("../assets/convert_to_job.png")
export const convertToQuote=require("../assets/convert_to_quote.png")
export const downloadPdf=require("../assets/download_pdf.png")
export const arrowRight=require("../assets/arrowRight.png")
export const requestWhiteIcon=require("../assets/requestIcon.png")
export const requestGreyIcon=require("../assets/RequestGrey.png")
export const quotewhiteIcon=require("../assets/whiteQuote.png")
export const quoteGreyIcon=require("../assets/GreyQuote.png")
export const jobWhiteIcon=require("../assets/whiteJob.png")
export const jobGreyIcon=require("../assets/GreyJob.png")
export const invoicewhiteIcon=require("../assets/whiteinvoice.png")
export const invoiceGreyIcon=require("../assets/GreyInvoice.png")
export const dropDownSelectIcon = require("../assets/dropDownSelectIcon.png");
export const threeDotsIcon = require("../assets/threeDotsIcon.png");
export const uploadButtonIcon = require("../assets/uploadIcon.png")

// extensionIcons
export const completedIcon = require("../assets/completedIcon.png");
export const jpgExtensionIcon = require("../assets/jpg-ext.png");
export const pngExtensionIcon = require("../assets/png-ext.png");
export const docExtensionIcon = require("../assets/doc-ext.png");
export const txtExtensionIcon = require("../assets/txt-ext.png");
export const pptExtensionIcon = require("../assets/ppt-ext.png");
export const pdfExtensionIcon = require("../assets/pdf-ext.png");
export const xlsExtensionIcon = require("../assets/xls-ext.png");

