import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
export const configJSON = require("./config");
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import { WithStyles } from "@material-ui/core"
import { performInvoiceAuthorizationCheck,performInvoiceDeleteAuthorizationCheck } from "../../CustomisableUserProfiles/src/utility.web";
import { apiCall, checkTokenExpired, navigateTo } from '../../../components/src/CommonFunction'
export interface DetailsCustomer {
    id?: string,
    type?: string,
    attributes?: AttributesCustomer

}

export interface AttributesCustomer {
    name?: string,
    email?: string,
    phone_number?: string,
    address?: string,
    state?: string,
    city?: string,
    zip_code?: string,
    notes?: string,
    quote_follow_up?: boolean,
    job_follow_up?: boolean,
    invoice_follow_up?: boolean,
    latitude?: string,
    longitude?: string,
    files?: null,
}

export interface RequestDetails {
    id?: string,
    type?: string,
    attributes?: {
        request_title?: string,
        account_id?: number,
        employee_id?: null,
        status?: string,
        site_id?: number,
        service_detail?: string,
        source?: string,
        other_source?: string,
        customer_id?: number,
        appointment_date?: string,
        internal_notes?: string,
        created_at?: string,
        updated_at?: string,
        appointment_time?: string,
        custmoer_name?: string,
        files?: null,
        request_id?: string,
        converted_to_quote?: boolean
        converted_to_job?: boolean,
        job_id?: number | string

    }
}
interface ErrorFormData {
    [key: string]: string;
}

// Customizable Area End


export interface Props extends WithStyles {
    navigation: any;
    id: string;
    history: any
    // Customizable Area Start
    location: any
    openToastHandler: any;
    t: (key: string) => string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    checkedC: boolean,
    isEdit: boolean,
    singleRequestDetails: RequestDetails,
    requestIdCreated: string
    requestEditForm: {
        title: string
        serviceDetails: string
        appointmentDate: any
        appointmentTime: string
        others: string
    };
    anchorEl: any
    anchorElV1: any
    getEmployeeData:object|[]
    requestEditStatus: boolean,
    openDeleteDailog: boolean,
    toasterStatus: boolean,
    requestSource: any,
    onSite: any
    internalNotes: string,
    editedInternalAttachments: any
    internalAttachments: any
    getCustomerPlace: any
    selectedCustomerDetails: DetailsCustomer
    locationStaus: boolean
    defaultCustomerLocation: {
        lat: number,
        lng: number
    },
    softDeleteStatus: string
    openPdfStatus: boolean
    customerSelected: boolean
    changeCustomerDailog: boolean
    siteDetails: any
    customerId: any
    editRequestLoading: boolean
    toggleModal: boolean
    selectedRequestSource: string
    selectedSourceValue: string
    othersError: string
    convertedFiles: any
    toasterStatusText: string
    timeFormat: any
    selectLang:string
    all_day:boolean
    editTeamMember:object[]
    isErrorFormData:ErrorFormData
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class RequestEditController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getRequestDetailsApiCallId: string = "";
    updateRequestApiCallId: string = "";
    getDeleteRequestApiCallId: string = "";
    getCustomerDetailsApiCallId: string = "";
    getSiteDetailsApiCallId: string = ""
    apiCallIdGetEmployee: string = ""
    mapRef: any;


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        const requestSource = [
            { id: 1, sourceName: `${this.props.t('request.sourceMenu.whatsapp')}`, sourceValue: "whatsapp" },
            { id: 2, sourceName: `${this.props.t('request.sourceMenu.instagram')}`, sourceValue: "instagram" },
            { id: 3, sourceName: `${this.props.t('request.sourceMenu.twitter')}`, sourceValue: "twitter" },
            { id: 4, sourceName: `${this.props.t('request.sourceMenu.phoneCall')}`, sourceValue: "phone_call" },
            { id: 5, sourceName: `${this.props.t('request.sourceMenu.email')}`, sourceValue: "email" },
            { id: 6, sourceName: `${this.props.t('request.sourceMenu.others')}`, sourceValue: "others" },
          ]
        this.mapRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        this.state = {
            checkedC: true,
            isEdit: false,
            singleRequestDetails: {},
            requestIdCreated: "",
            requestEditForm: {
                title: "",
                serviceDetails: "",
                appointmentDate: "",
                appointmentTime: "",
                others: "",
            },
            getEmployeeData:[],
            anchorEl: null,
            anchorElV1: null,
            requestEditStatus: false,
            openDeleteDailog: false,
            toasterStatus: false,
            requestSource: requestSource,
            onSite: false,
            internalNotes: "",
            editedInternalAttachments: [],
            internalAttachments: [],
            getCustomerPlace: null,
            selectedCustomerDetails: {},
            locationStaus: false,


            // Customizable Area Start
            defaultCustomerLocation: {
                lat: 25.276987,
                lng: 55.296249
            },
            softDeleteStatus: "cancelled",
            openPdfStatus: false,
            customerSelected: false,
            changeCustomerDailog: false,
            siteDetails: {},
            customerId: "",
            editRequestLoading: false,
            toggleModal: false,
            selectedRequestSource: "",
            selectedSourceValue: "",
            othersError: "",
            convertedFiles: [],
            toasterStatusText: "",
            timeFormat: "",
            selectLang:"",
            all_day:false,
            editTeamMember:[],
            isErrorFormData:{}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        const selectedLanguage = await getStorageData("lang");
        this.setState({ selectLang: selectedLanguage })
        localStorage.removeItem('convertType')
        window.scrollTo(0, 0);
        this.getEmployee()
        const route = await getStorageData('calendarRedirection')
        if (route) {
            const params = new URLSearchParams(window.location.search);
            params.set('from', 'Calendar');
            window.history.pushState({}, '', `?${params.toString()}`);
        }
        if (!localStorage.getItem("authToken")) {
            this.props.navigation.history.push("/Login");
        }
        this.setState({
            timeFormat: await getStorageData('timeFormat')
        })
        this.getRequestDetails()
        this.setState({ editRequestLoading: true })
        const RequestCreatedStatus = await getStorageData(configJSON.requestStatus.created)
        await removeStorageData(configJSON.requestStatus.created)
        if (RequestCreatedStatus) {
            this.setState({ toasterStatus: true, toasterStatusText: `${this.props.t('request.toasterStatusText.create')}` }, () => {
                { this.props.openToastHandler(this.state.toasterStatusText, "success") }
            });
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId === this.getRequestDetailsApiCallId) {
                this.getRequestResponseCheck(responseJson)

            } else if (apiRequestCallId === this.updateRequestApiCallId) {
                this.updateRequestResponseCheck(responseJson)

            } else if (apiRequestCallId === this.getDeleteRequestApiCallId) {
                this.deleteRequestResponseCheck(responseJson)

            } else if (apiRequestCallId === this.getCustomerDetailsApiCallId) {
                this.getCustomerDetailsApiResponse(responseJson)
            } else if (apiRequestCallId === this.getSiteDetailsApiCallId) {
                this.siteInformationApiResponce(responseJson)
            }else if (apiRequestCallId === this.apiCallIdGetEmployee) {
                this.getEmployeeApiResponse(responseJson)
              }
        }
        // Customizable Area End
    }

    sourecValue = () => {
        const source = this.state.selectedRequestSource
        this.state.requestSource.map((souce: any) => {
            if (souce.sourceValue === source) {
                this.setState({
                    selectedRequestSource: souce.sourceName,
                    selectedSourceValue: souce.sourceValue,
                })
            }

        })
    }
    handleButton = (event: any) => {
        this.setState({
            anchorElV1: event&&event.currentTarget
        }, this.handleButtonCallbackV1);
    }
    handleButtonCallbackV1 = async () => {
        const isAuthorized = await performInvoiceAuthorizationCheck("request");
        this.handleButtonCallback(isAuthorized)
    }
    handleButtonCallback = (isAuthorized: any) => {
        if (!isAuthorized) {
            this.setState({
                anchorEl: this.state.anchorElV1
            })
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
        }
    }
    handleButtonClose = () => {
        this.setState({
            anchorEl: null
        })
    };
    handleMoreActions = (type: string) => {
        this.handleButtonClose()
        if (type === 'actionQuote') {
            localStorage.removeItem('quote_id')
            this.props.navigation.history.push("/Quote/Create", {
                requestId: this.state.singleRequestDetails.id,
                jobId: this.state.singleRequestDetails.attributes?.job_id,
                convertedType: "RequestTo"
            })
        } else if (type === 'actionJob') {
            this.props.navigation.history.push("/Job/Create", {
                requestId: this.state.singleRequestDetails.id,
                jobId: this.state.singleRequestDetails.attributes?.job_id,
                convertedType: "RequestTo"
            })
        } else if (type === 'actionPdf') {
            this.openpdf()
        }

    }

    requestTitleValidation = (): ErrorFormData => {
        const errors = { title: "" };
        this.validateTitle(this.state.requestEditForm.title, errors)
        this.clean(errors)
        return errors;
    }
    clean(obje: { [x: string]: string | undefined; title?: string; }) {
        for (let propName in obje) {
            if (obje[propName]?.length === 0) {
                delete obje[propName];
            }
        }
        return obje
    }
    validateTitle = (title: string, errors: ErrorFormData ) => {
        if (!title) {
          errors.title = `${this.props.t('request.errorText.titleEmptyText')}`
        } else if ((title).length > 40) {
          errors.title = `${this.props.t('request.errorText.titleCharacterslengthText')}`
        }
      }

      handleRequestEditChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ requestEditForm: { ...this.state.requestEditForm, [event.target.name]: event.target.value } },
            ()=>{
                this.setState({ isErrorFormData: {...this.state.isErrorFormData,...this.requestTitleValidation()} })
        });
    };
    
    handleEditCheckAllDay = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ all_day: event.target?.checked })
    };
    handleEditCheckOnSite = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ onSite: event.target?.checked })
    };
    handleChangeEditTeam = (selected: { value: string; }[]) => {
        this.setState({editTeamMember:selected})
    }
   

    handleEditRemoveTeam = (employeeId: string) => {
        this.setState({
            editTeamMember: this.state.editTeamMember.filter((item: object|string) => {
                return item !== employeeId
            })
        })
    }
    handleCancel = () => {
        if (this.state.requestEditStatus) {
            this.setState({
                requestEditStatus: false
            })
        } else {
            this.props.navigation.history.push("/Request/Create")
        }
    }
    handleRequestEdit = async () => {
        const isAuthorized = await performInvoiceAuthorizationCheck("request");
        this.handleRequestEditCallback(isAuthorized)
    }
    handleRequestEditCallback = (isAuthorized: any) => {
        if (!isAuthorized) {
            this.setState({
                requestEditStatus: true
            })
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
        }
    }

    handleDeleteDialog = async () => {
        const isAuthorized = await performInvoiceDeleteAuthorizationCheck("request");
        this.handleDeleteDialogCallback(isAuthorized)
    }

    handleDeleteDialogCallback = (isAuthorized: any) => {
        if (!isAuthorized) {
            this.setState({ openDeleteDailog: true })
        } else {
            this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
        }
    }
    handleCloseDeleteDialog = () => {
        this.setState({ openDeleteDailog: false })
    }
    handleToasterClose = () => {
        this.setState({
            toasterStatus: false
        })
    }

    // Location Data
    onMapLoad = (map: google.maps.Map) => {
        this.mapRef.current = map;
        const zoomInButton = document.createElement('button');
        zoomInButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#1c18af"/>
            <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" fill="#1c18af"/>
        </svg>`;
        zoomInButton.style.backgroundColor = 'white';
        zoomInButton.style.marginTop = '10px';
        zoomInButton.style.marginBottom = '10px';
        zoomInButton.style.border = 'none';
        zoomInButton.style.paddingLeft = '5px';
        zoomInButton.style.cursor = 'pointer';
        zoomInButton.style.padding = '6px 3px 0px 5px';
        zoomInButton.style.borderRadius = '4px';
        zoomInButton.title = 'Zoom In';
        zoomInButton.addEventListener('click', () => map?.setZoom((map?.getZoom() ?? 0) + 1));
        const zoomOutButton = document.createElement('button');
        zoomOutButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path fill="#1c18af" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
        </svg>`;
        zoomOutButton.style.border = 'none';
        zoomOutButton.style.cursor = 'pointer';
        zoomOutButton.style.backgroundColor = 'white';
        zoomOutButton.style.marginBottom = '10px';
        zoomOutButton.style.padding = '6px 3px 0px 5px';
        zoomOutButton.style.borderRadius = '4px';
        zoomOutButton.style.paddingLeft = '5px';
        zoomOutButton.title = 'Zoom Out';
      
        zoomOutButton.addEventListener('click', () =>   map?.setZoom((map?.getZoom() ?? 0) - 1));
      
        const controlDiv = document.createElement('div');
        controlDiv.style.position = 'absolute';
        controlDiv.style.marginRight = '20px';
        controlDiv.style.display = 'flex';
        controlDiv.style.flexDirection = 'column';
        controlDiv.style.zIndex = '1';
        controlDiv.style.marginBottom = '20px';
        controlDiv.style.bottom = '100px';
        controlDiv.style.right = '10px';
        controlDiv.style.justifyContent = 'space-between'; 
        controlDiv.appendChild(zoomInButton);
        controlDiv.appendChild(zoomOutButton);
        const controlPosition = google.maps.ControlPosition.RIGHT_BOTTOM;
        map.controls[controlPosition].push(controlDiv);
      }; 
    locationData = (customer: any) => {
        const selectedPlace = {
            lat: parseFloat(customer.latitude),
            lng: parseFloat(customer.longitude),
        };
        this.setState({ getCustomerPlace: selectedPlace });
        const map = this.mapRef.current;
        if (map) {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(selectedPlace);
            map.fitBounds(bounds, 100);
            map.setZoom(15);
        }

    }

    //Api calls
    handleDeleteRequest = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "token": token
        };
        this.setState({ editRequestLoading: true })

        const idRequest = this.props.navigation?.history.location?.state.requestId

        let deleteRequestBody = new FormData();
        deleteRequestBody.append("data[status]", this.state.softDeleteStatus);
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDeleteRequestApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createRequest}/${idRequest}&lang=${this.state.selectLang}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), deleteRequestBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateApiMethodtype
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    // Customizable Area Start
    getCustomerDetailsApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ editRequestLoading: false })
            this.setState({
                selectedCustomerDetails: responseJson.data,
                locationStaus: true
            })
        }
    }
    getEmployeeApiResponse(responseJson: any) {
        if (responseJson && !responseJson.errors) {
          this.setState({ getEmployeeData: responseJson.data})
        } else {
            if (responseJson && responseJson.errors) {
                checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
                this.setState({
                    getEmployeeData: []
                })
            }
        }
      }
    getRequestResponseCheck = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            if (responseJson.message === "you are not authorized") {
                return this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
            }
            const requestEditForm = {
                title: responseJson.data.attributes?.request_title,
                serviceDetails: responseJson.data.attributes.service_detail,
                appointmentDate: new Date(responseJson.data.attributes.appointment_date) || "",
                appointmentTime: responseJson.data.attributes.appointment_time || "",
                others: responseJson.data.attributes.other_source,
            }
            const requestSource = responseJson.data.attributes?.source
            const attachments = responseJson.data.attributes?.files
            const employeeData = responseJson.data.attributes.employees.map((item: { id: string })=>{
                return item.id
            })
            this.setState({
                singleRequestDetails: responseJson.data,
                requestEditForm: requestEditForm,
                internalNotes: responseJson.data.attributes.internal_notes,
                editedInternalAttachments: attachments,
                selectedRequestSource: requestSource,
                all_day:responseJson.data.attributes.all_day,
                editTeamMember:employeeData,
                onSite:responseJson.data.attributes.on_site

            }, () => {
                this.sourecValue()
                this.getCustomerDetails()
                this.getSelectedSiteDetails()
            })
        }
        localStorage.removeItem('customerTabId')
    }
    updateRequestResponseCheck = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ editRequestLoading: false })
            const requestEditForm = {
                title: responseJson.data.data.attributes?.request_title,
                serviceDetails: responseJson.data.data.attributes?.service_detail,
                appointmentDate: new Date(responseJson.data.data.attributes?.appointment_date)|| "",
                appointmentTime: responseJson.data.data.attributes?.appointment_time || "",
                others: responseJson.data.attributes?.other_source,
            }
            const requestSource = responseJson.data.data.attributes?.source
            this.setState({
                singleRequestDetails: responseJson.data.data,
                requestEditForm: requestEditForm,
                requestEditStatus: false,
                toasterStatus: true,
                internalNotes: responseJson.data.data.attributes?.internal_notes,
                editedInternalAttachments: responseJson.data.data.attributes.files,
                selectedRequestSource: requestSource,
                internalAttachments: [],
                toasterStatusText: `${this.props.t('request.toasterStatusText.updated')}`
            }, async () => {
                this.props.openToastHandler(this.state.toasterStatusText, "success");
                this.sourecValue()
                this.getCustomerDetails()
                this.getSelectedSiteDetails()
                this.checkRedirect();

            })
            this.setState({ openDeleteDailog: false, othersError: "" })
        }
    }
    checkRedirect = async () => {
        const params = new URLSearchParams(window.location.search || '');
        const paramValue = params.get('from');
        if (paramValue) {
            this.redirectUrl(paramValue);
        }
    }
    redirectUrl = (url: string) => {
        if (url) {
            this.clearRedirectUrl()
            navigateTo({ props: this.props, screenName: "Calendar" })
        }
    };
    clearRedirectUrl = async () => {
        await removeStorageData("type");
        await removeStorageData("calendarRedirection");
        await removeStorageData("selectedEventId");
        await removeStorageData("calendarView")
    };
    deleteRequestResponseCheck = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                openDeleteDailog: false,
                editRequestLoading: false
            })
            this.props.openToastHandler(`${this.props.t('request.toasterStatusText.delete')}`, "success");
        }
    }
    handleUpdateRequest = () => {    
        if(this.state.selectedSourceValue === "others" && !this.state.requestEditForm.others) {
            this.setState({ othersError: configJSON.errorText.sourceErrorText });
        } 
        else if (this.state.requestEditForm.title === ''){
            this.requestTitleValidation();
        }
        else {
            this.updateRequest();
        }     
    }
    translateToEnglishTime = (arabicTime: string) => {
        const [hour, minute, period] = arabicTime.split(/[ :]/);
        let translatedHour = String(hour);
        let translatedMinute = String(minute);
        
        const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
        const englishNumerals = "0123456789";
        
        for (let checktime = 0; checktime < arabicNumerals.length; checktime++) {
          translatedHour = translatedHour.replace(new RegExp(arabicNumerals[checktime], 'g'), englishNumerals[checktime]);
          translatedMinute = translatedMinute.replace(new RegExp(arabicNumerals[checktime], 'g'), englishNumerals[checktime]);
        }
    
        let translatedPeriod = period;
        if (period === "مساء") {
          translatedPeriod = "PM";
        } else if (period === "صباخا") {
          translatedPeriod = "AM";
        }
    
        if (period === "مساء" && parseInt(translatedHour, 10) < 12) {
          translatedHour = String(parseInt(translatedHour, 10) + 12);
        }
    
        translatedHour = translatedHour.padStart(2, '0');
        translatedMinute = translatedMinute.padStart(2, '0');
    
        return `${translatedHour}:${translatedMinute} ${translatedPeriod || ""}`;
      };
    updateRequest = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "token": token
        };
        this.setState({ editRequestLoading: true })
        let updateRequestBody = new FormData();
        updateRequestBody.append("data[request_title]", this.state.requestEditForm.title);
        //@ts-ignore
        updateRequestBody.append("data[customer_id]", this.state?.selectedCustomerDetails?.id);
        updateRequestBody.append("data[site_id]", this.state.siteDetails?.id || this.state.singleRequestDetails?.attributes?.site_id);
        updateRequestBody.append("data[service_detail]", this.state.requestEditForm.serviceDetails);
        if(this.state.selectedSourceValue){updateRequestBody.append("data[source]", this.state.selectedSourceValue);}
        if(this.state.selectedSourceValue === "others") {
            updateRequestBody.append("data[other_source]", this.state.requestEditForm.others || "");
          }
        updateRequestBody.append("data[appointment_date]", this.state.requestEditForm.appointmentDate);
        updateRequestBody.append("data[appointment_time]", this.translateToEnglishTime(this.state.requestEditForm.appointmentTime));
        updateRequestBody.append("data[on_site]", this.state.onSite);
        updateRequestBody.append("data[internal_notes]", this.state.internalNotes);
        updateRequestBody.append("data[all_day]", this.state.all_day.toString());
        updateRequestBody.append("data[employee_ids]", this.state.editTeamMember.toString());
        for (let file of this.state.internalAttachments) {
            updateRequestBody.append("data[files][]", file);
        }
        for (let file of this.state.convertedFiles) {
            updateRequestBody.append("data[file_id][]", file.id);
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateRequestApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createRequest}/${this.state.singleRequestDetails.id}?lang=${this.state.selectLang}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), updateRequestBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateApiMethodtype
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getRequestDetails = async () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        this.setState({ editRequestLoading: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const selectedEventId = await getStorageData('selectedEventId')
        const customerReqId = await getStorageData('customerTabId')
        const requestId = this.props.navigation.history.location.state?.requestId||selectedEventId||customerReqId
        this.getRequestDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSingleRequestDetails}/${requestId}?lang=${this.state.selectLang}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getEmployee = async () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const apiEndPoint = configJSON.getEmployeeApiEndPoint
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        const getEmployeeApiCall = apiCall({
          header: header,
          httpBody: null,
          url: `${apiEndPoint}`,
          httpMethod: configJSON.validationApiMethodType,
        });
        this.apiCallIdGetEmployee = getEmployeeApiCall.messageId;
        runEngine.sendMessage(getEmployeeApiCall.id, getEmployeeApiCall);
      }
    getSelectedSiteDetails = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSiteDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.siteEndPoint}/${this.state.singleRequestDetails?.attributes?.site_id}?lang=${this.state.selectLang}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getCustomerDetails = () => {
        const token = localStorage.getItem(configJSON.storageToken)
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCustomerDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCustomerDetails}?id=${this.state.singleRequestDetails?.attributes?.customer_id}&lang=${this.state.selectLang}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateInternalNotes = (notes: string) => {
        this.setState({ internalNotes: notes })


    }
    updateInternalAttachments = (files: any) => {
        this.setState({
            internalAttachments: files
        })
    }

    openpdf = () => {
        this.setState({
            openPdfStatus: true
        })
        this.handleButtonClose()
    }
    closePdf = () => {
        this.setState({
            openPdfStatus: false
        })
    }
    changeCutomerDailog = () => {
        if (this.state.requestEditStatus) {
            this.setState({
                changeCustomerDailog: true
            })
        }
    }
    changeCutomerClose = () => {
        this.setState({
            changeCustomerDailog: false
        })
    }
    getCustomerDailogStatus = (Status: boolean, customerSelected: boolean, siteDetails: any) => {
        this.setState({
            changeCustomerDailog: Status,
            customerSelected: customerSelected,
            siteDetails: siteDetails
        })
    }
    getEditCustomerDetails = (customerDetails: any) => {
        this.setState({
            selectedCustomerDetails: customerDetails,
            customerId: customerDetails.id
        })
    }
    handleToggleRequestModalOpen = () => {
        if (this.state.requestEditStatus) {
            this.setState({ toggleModal: !this.state.toggleModal });
        }
    }
    handleStatusRequestModalClose = () => {
        this.setState({ toggleModal: false });
    }

    handleSelectSource = (item: any) => {
        this.setState({
            selectedRequestSource: item.sourceName,
            selectedSourceValue: item.sourceValue
        }, () => {
            this.handleStatusRequestModalClose()
        })
    }
    getConvertedFiles = (converted: any) => {
        this.setState({
            convertedFiles: converted
        })
    }

    handleEditChangeHours = (timeValue: any) => {
        this.setState({
            requestEditForm: { ...this.state.requestEditForm, appointmentTime: timeValue }
        })
    }
    getEditedFile = (editedFiles: any) => {
        this.setState({
            editedInternalAttachments: editedFiles
        })
    }
    cancelRequest = () => {
        this.setState({
            requestEditStatus: false,
        },()=>{
            this.getRequestDetails()
        })
    }
    siteInformationApiResponce = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                siteDetails: responseJson.data
            }, () => {
                this.locationData(this.state.siteDetails)
            })
        }

    }


    getCustomerSelectedStatus = (customerSelected: boolean, customerDailog: boolean) => {
        this.setState({
            customerSelected: customerSelected,
            changeCustomerDailog: customerDailog,

        })
    }
    getSiteDetails = (details: any) => {
        this.setState({
            siteDetails: details
        }, () => {
            this.locationData(this.state.siteDetails)
        })
    }
    getCustomerDailogStatus1 = (selected: boolean) => {
        // this.setState({
        //   selectButtonStatus: selected,
        // })
    }
    getSiteSelectedStatus = (siteSelected: boolean) => {
        // this.setState({ siteSelected: siteSelected })
    }

    handleEditDateChange = (date: any) => {
        this.setState({ requestEditForm: { ...this.state.requestEditForm, appointmentDate: date } });
    }
    // Customizable Area End
}
