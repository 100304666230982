import React from 'react';
import {
   Button,TextField,MenuItem,Tabs
} from '@material-ui/core';
import {
  Theme,createStyles, withStyles
} from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { CONSTANTS } from './theme';
import Autocomplete from '@material-ui/lab/Autocomplete';
const selectedLanguage = localStorage.getItem("lang");
interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
interface TextfieldProps {
    languageSelected: string;
}
export const StyledTextField = withStyles({
    root: {
      width: "100%",
      "& .MuiInputBase-root": {
        borderRadius: "8px",
        paddingLeft: (props: TextfieldProps) => props.languageSelected === 'en' && "2rem",
        paddingRight: (props: TextfieldProps) => props.languageSelected === 'ar' && "2.5rem",
        color: CONSTANTS.color.coolDarkGrey,
      },
      "& .MuiOutlinedInput-inputAdornedEnd":{
        paddingRight: (props: TextfieldProps) => props.languageSelected === 'ar' && "0.9rem",
      }
    }
})(TextField);
export const ContainedButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: CONSTANTS.color.darkBlue,
            width: "230px",
            height: "67px",
            borderRadius: 10,
            color: theme.palette.common.white,
            fontSize: "22px",
            fontWeight: theme.typography.fontWeightRegular,
            textTransform: "capitalize" as const,
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
                backgroundColor: CONSTANTS.color.blueViolet,
                color: theme.palette.common.white,
                cursor: "pointer",
            },
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                height: "48px",
            },
            [theme.breakpoints.between("sm", 'md')]: {
                width: "44%",
            },
        }
    }))(Button)
export const CreateQuote = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: CONSTANTS.color.darkBlue,
            padding:"0 20px",
            height: "67px",
            borderRadius: 10,
            color: theme.palette.common.white,
            fontSize: "22px",
            fontWeight: theme.typography.fontWeightRegular,
            textTransform: "none" as const,
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
                backgroundColor: CONSTANTS.color.blueViolet,
                color: theme.palette.common.white,
                cursor: "pointer",
            },
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                height: "48px",
            },
        }
    }))(Button)
export const SelectCustomerButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: CONSTANTS.color.darkBlue,
            width: "327px",
            minHeight: "48px",
            borderRadius: 10,
            color: theme.palette.common.white,
            fontSize: "22px",
            fontWeight: theme.typography.fontWeightRegular,
            textTransform: "capitalize",
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
                backgroundColor: CONSTANTS.color.blueViolet,
                color: theme.palette.common.white,
                cursor: "pointer",
            },
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        }
    }))(Button)
export const ArcheiveButton = withStyles((theme:Theme) =>
    createStyles({
        root: {
            width: "230px",
            height: "67px",
            fontSize: "22px",
            fontWeight: 500,
            textTransform: "capitalize",
            color: "#ff5454",
            borderRadius: "10px",
            background: "#ffffff",
            border: "1px solid #ff5454",
            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
            '&:hover': {
              backgroundColor: '#ff5454',
              color: theme.palette.common.white,
              cursor: "pointer"
            },
            [theme.breakpoints.down('xs')]: {
                height:'48px',
                width:'100%'
            },
            [theme.breakpoints.between("sm", 'md')]: {
                width: "44%",
            },
        }

    }))(Button)
export const CancelButton = withStyles((theme:Theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.palette.text.primary}`,
            backgroundColor:theme.palette.common.white,
            width: "200px",
            height: "67px",
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: "22px",
            borderRadius: 10,
            color:theme.palette.text.primary,
            textTransform: "capitalize",
            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
            '&:hover': {
              backgroundColor: '#999999',
              color: theme.palette.common.white,
              cursor: "pointer"
            },
            [theme.breakpoints.down('xs')]: {
                height: "48px",
                width:'100%'
            },
            [theme.breakpoints.between("sm", 'md')]: {
                width: "44%",
            },
        }
    }))(Button)
export const OutlinedButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `1px solid  ${CONSTANTS.color.darkBlue}`,
            backgroundColor: "#ffffff",
            width: "230px",
            height: "67px",
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "22px",
            borderRadius: 10,
            color: CONSTANTS.color.darkBlue,
            textTransform: "capitalize",
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
                backgroundColor: CONSTANTS.color.blueViolet,
                color: theme.palette.common.white,
                border: "none",
                cursor: "pointer"
            },
            [theme.breakpoints.down('xs')]: {
                height:'48px',
                width:'100%'
            },
            [theme.breakpoints.between("sm", 'lg')]: {
                width: "44%",
            },
        }
    }))(Button)
export const CustomizedTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiInputBase-root": {
                borderRadius: 8,
                fontFamily: theme.typography.fontFamily,
                color: CONSTANTS.color.grey
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)",
                borderWidth: "2px"
            },
            "& .MuiOutlinedInput-root:hover": {
                borderColor: "rgba(0, 0, 0, 0.23)",

            },
            "& .MuiInputAdornment-positionEnd":{
                marginLeft:"0px",
                marginRight:"0px"
            },
            "& .MuiOutlinedInput-adornedEnd":{
                paddingRight:"10px"
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "white"
            },
            "& .MuiSvgIcon-root": {
                fontSize: "25px"
            }
        }
    }))(TextField);
export const MoreActionStyledMenu = withStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "fit-content",
            width: "100%",
            borderRadius: "5px",
            background: theme.palette.common.white,
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
            marginLeft: selectedLanguage === "en" ? "-13px" : "",
            marginTop: "-110px",
            overflow: "hidden",
            [theme.breakpoints.down('xs')]: {
                marginLeft: "0px",

            }
        },
    }))((props: MenuProps) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));
export const CustomerText = withStyles((theme: Theme) => ({
    root: {
        '& .MuiListItemIcon-root': {
            display: 'flex',
            justifyContent: "flex-end",
            alignItems: "center",
        },
        '& .MuiListItemText-primary': {
            color: CONSTANTS.color.darkBlack,
            fontSize: 18,
            fontWeight: theme.typography.fontWeightMedium,
            cursor: "pointer",
            textTransform: "capitalize",
        },
    },
}))(MenuItem);
export const StyledTabs = withStyles((theme: Theme) => ({
    root: {
        "& .MuiTabs-flexContainer": {
            justifyContent: "space-between",
            [theme.breakpoints.down("xs")]: {
                alignItems: 'center',
                marginTop: "20px"

            },

        },
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 20,
            width: '100%',
            backgroundColor: '#05037a',
        },
    },
    }))((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

    export const CustomAutocomplete = withStyles((theme:Theme) => ({
        option: {
          color: theme.palette.common.black,
        },
      }))(Autocomplete);
