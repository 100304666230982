import React from "react";

import {
  // Customizable Area Start
  Grid,
  Typography, Divider
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import withRequestStyles from "../../RequestManagement/src/RequestStyles.web";
import JobActionsController , { Props } from "./JobActionsController.web";
import { Attachments } from "../../../components/src/UploadedFiles.web";
import moment from 'moment';
import 'moment/locale/ar';
const selectedLanguage = localStorage.getItem("lang");
if (selectedLanguage === 'ar') {
  moment.locale("ar");
}
// Customizable Area End


export class ContentDrawer extends JobActionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  formatDate = (date: moment.Moment, format: string): string => {
    switch (format) {
      case 'DDth Of Month YYYY': {
        const day: string = date.format('D');
        const month: string = date.format('MMMM');
        const year: string = date.format('YYYY');
        const dayNum = parseInt(day);
        let ordinalSuffix: string;

        if (dayNum % 10 === 1 && dayNum !== 11) { ordinalSuffix = 'st';
        } else if (dayNum % 10 === 2 && dayNum !== 12) { ordinalSuffix = 'nd';
        } else if (dayNum % 10 === 3 && dayNum !== 13) { ordinalSuffix = 'rd';
        } else { ordinalSuffix = 'th'}
        return `${day}${ordinalSuffix} Of ${month} ${year}`;
      }
      case 'Month DD, YYYY':
        return date.format('MMMM D, YYYY');
      case 'Day, DD MMM YYYY':
        return date.format('dddd, D MMM YYYY');
      case 'YYYY-DDD':
        return date.format('YYYY-DDD');
      case 'الشهر يوم، سنة':
        return date.locale('ar').format('MMMM D، YYYY');
      case 'شهر/يوم/سنة': {
        const day: string = date.format('DD');
        const month: string = date.locale('ar').format('MMMM');
        const year: string = date.format('YYYY');
        return `${month}/${day}/${year}`;
      }
      case 'يوم/شهر/سنة': {
        const day: string = date.format('DD');
        const month: string = date.locale('ar').format('MM');
        const year: string = date.format('YYYY');
        return `${day}/${month}/${year}`;
      }
      case 'سنة-شهر-يوم': {
        const day: string = date.format('DD');
        const month: string = date.format('MM');
        const year: string = date.format('YYYY');
        return `${year}-${month}-${day}`;
      }
      case 'اليوم، يوم شهر سنة': {
        const dayOfWeek: string = date.format('dddd');
        const day: string = date.format('D');
        const month: string = date.format('MMMM');
        const year: string = date.format('YYYY');
        return `${dayOfWeek}، ${day} ${month} ${year}`;
      }
      case 'يوم من الشهر سنة': {
        const day: string = date.format('D');
        const month: string = date.format('MMMM');
        const year: string = date.format('YYYY');
        return `${day} من ${month} ${year}`;
      }
      case 'سنة-يوم': {
        const year: string = date.format('YYYY');
        const day: string = date.format('D');
        return `${year}-${day}`;
      }
      default:
        return date.format(format);
    }
  }
  
  schedulingDetails=()=>{
    const {classes,t}=this.props
    const dateFormate = localStorage.getItem('dateFormate') || "MMMM DD, YYYY";
    const appointmentDate = this.props.details?.attributes?.appointment_date;
    const date = moment(appointmentDate);
    const formattedDate = this.formatDate(date, dateFormate);
    return(
    <>
      { this.props.modeType==="QuoteTo"?
      <>
      <Typography variant="body1" color="textPrimary" className={`${classes.subTitle20}  ${classes.gutterTop2}`} >
        {t('quote.quoteDate')} - {this.props.details && this.props.details?.attributes?.quote_date}
       </Typography>
       <Typography variant="body1" color="textPrimary" className={`${classes.subTitle20} ${classes.gutterTop2}`} >
        {t('invoicePdf.dueDate')} - {this.props.details && this.props.details?.attributes?.due_date}
       </Typography>
       </>:
       <>
         <Typography variant="body1" color="textPrimary" className={`${classes.subTitle20}  ${classes.gutterTop2}`} >
          {t('request.subTitles.dateText')} - {formattedDate}
        </Typography>
       <Typography variant="body1" color="textPrimary" className={`${classes.subTitle20} ${classes.gutterTop2}`} >
          {t('request.subTitles.timeText')} - {this.props.details && this.props.details?.attributes?.appointment_time}
       </Typography>
       </>
       }
    
    </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,t } = this.props
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Grid item xs={12}>
          <Typography variant="body1" className={`${classes.subTitle20} ${classes.drawerHeader}`}>

            {t('quote.drawer.goBack')} &nbsp;
            <Typography display="inline" variant="body1" className={`${classes.requestText} ${classes.cursor}`}
            // onClick={this.handleRequestBack} 
            >
              {this.props.modeType === "QuoteTo" ? `${t('creationProcess.quote')}` : `${t('request.pdfOrderLabel')}`}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.requestGrid}>
            <Typography variant="subtitle1" className={classes.titleHeading26}>
              {this.props.modeType === "QuoteTo" ? `${t('creationProcess.quote')}` : `${t('request.pdfOrderLabel')}`}
            </Typography>
            <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>
              {this.props.details && new Date(this.props.details?.attributes?.created_at).toLocaleDateString()}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" className={classes.requestDetailsGrid}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.gutterTop2}`}>
                {this.props.modeType === "QuoteTo" ?

                  this.props.details && this.props.details?.attributes?.quote_id :
                  this.props.details && this.props.details?.attributes?.request_id

                }
              </Typography>

            </Grid>
            <Grid item xs={12} className={classes.gutterTop2}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between" onClick={this.props.seeDetails}>
                <div className={classes.lightText}>
                  {this.props.modeType === "QuoteTo" ? `${t('quote.quoFor')}` : `${t('request.reqFor')}`}
                    <span className={classes.ml_6}>{this.props.details && this.props.details?.attributes?.custmoer_name}</span>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.gutterTop2}`}>{t('request.serviceText')}</Typography>
              <Typography variant="body1" color="textPrimary" className={classes.subTitle20}> {this.props.details && this.props.details?.attributes?.service_detail}</Typography>
            </Grid>
            {this.props.modeType != "QuoteTo" &&
              <>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.gutterTop2}`}>{t('request.source')}</Typography>
                  <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>

                    {this.props.details && this.props.details?.attributes?.source}
                  </Typography>

                </Grid>
              </>
            }
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.gutterTop2}`}  >{this.props.modeType ==="QuoteTo"?`${t('quote.quoteText')}`:`${t('request.scheduling')}`}</Typography>
          {this.schedulingDetails()}

            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={`${classes.titleHeading26} ${classes.gutterTop2}`} >{t('internalNote.title')}</Typography>
              <Typography variant="body1" color="textPrimary" className={classes.subTitle20}>
                {this.props.details && this.props.details?.attributes?.internal_notes}
              </Typography>
            </Grid>
            <Attachments
             details={this.props.details}
             handleDownloadClick={this.handleDownloadClick}
            Icons={this.state.extensionIcons}/>
            
          </Grid>
        </Grid>

      </>


      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ConvertToJobStyles = withCommonStyles(ContentDrawer)
const GetRequestStyles = withRequestStyles(ConvertToJobStyles)
export default (GetRequestStyles);
// Customizable Area End
